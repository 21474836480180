import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { t } from 'ttag';
import useOrderContext from '../../contexts/order/useOrderContext';
import useBasketContext from '../../contexts/basket/useBasketContext';
import { Timeslot } from '../../contexts/site/site-context';
import useSiteContext from '../../contexts/site/useSiteContext';
import { getDisplayName } from '../../services/channel';
import {useLocalStorage} from "../../hooks/localstorage";
import {iLog} from "../../index";
import mixpanel from "mixpanel-browser";


type TimeslotSelectProps = {
    timeslots: Timeslot[] | null;
    background?: boolean;
    disabledSelect?: boolean;
    selectedTimeslotId?: string | null;
};

const TimeslotSelect = ({ timeslots, background, selectedTimeslotId = null, disabledSelect = false }: TimeslotSelectProps) => {
    const orderContext = useOrderContext();
    const basketContext = useBasketContext();

    const siteContext = useSiteContext();

    const [storedBasket, setStoredBasket] = useLocalStorage('basket', null);

    //const { values, errors, touched, submitForm, handleChange, setFieldValue } = useFormikContext<CustomerFormObject>();

    useEffect(() => {

        if ( siteContext.site && !siteContext.timeslots?.length ) {
            iLog('timeslot useEffect - calling fetch timeslot', [siteContext.site, basketContext.validatedSite]);

            siteContext.fetchTimeslots(
                siteContext.site?.uid ?? basketContext.validatedSite?.uid ?? '',
                siteContext.flow);
        }

    }, [siteContext.site]);

    useEffect(() => {


        if ( selectedTimeslotId && timeslots?.length ) {
            let found = timeslots?.find((t) => selectedTimeslotId === t.id);
            iLog('Selecting timeslot from storage', found);
            handleTimeslotSelected(found);
        }

    }, [timeslots, selectedTimeslotId]);

    const handleTimeslotSelect = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        //console.debug('timeslot changed', e.target.value);

        handleTimeslotSelected(timeslots?.find((t) => e.target.value === t.id));
        //handleChange(e);
    };

    const handleTimeslotSelected = (timeslot: Timeslot | undefined) => {
        iLog('Attempting to set timeslot to basketContext', timeslot);

        if (timeslot) {
            // orderContext.setTimeslot(timeslot);
            basketContext.setTimeslot(timeslot);
            //setFieldValue('timeslot', timeslot.id);

            setStoredBasket({
                ...(storedBasket ?? {}),
                timeslot: timeslot
            });

            mixpanel.track('timeslot selected');

        } else {
            console.warn('No timeslot passed on timeslot select.');
        }
    };

    const renderOptions = () => {
        if (!timeslots || timeslots?.length === 0) {
            return <option key="ts-none" disabled={true}>{t`No timeslots available`}</option>;
        }

        let renderedOptions = [];

        renderedOptions.push(
            <option
                key="ts-sel"
                //selected={selectedTimeslotId ? false : true}
                //disabled={true}
            >
                {t`Select a ` + getDisplayName(siteContext.channel)?.toLocaleLowerCase() + t` time`}
            </option>,
        );

        renderedOptions.push(
            timeslots?.map((timeslot) => (
                <option
                    key={`ts-${timeslot.id}`}
                    value={timeslot.id}
                    //selected={selectedTimeslotId === timeslot.id}
                >
                    {timeslot.name}
                </option>
            )),
        );

        return renderedOptions;
    };

    return (
        <>
{/*
            <StyledDropdown background={background} selectedTimeslot={basketContext?.timeslot !== null}>
*/}
            <StyledDropdown background={background} selectedTimeslot={basketContext?.timeslot !== null}>
                <i className="clock feather feather-clock feather-18 brand-color-filter" />
                <div>
                    {/*<span className="dropdown__label">Collection from {siteContext.site?.name ?? '/'}</span>*/}
                    <Form.Control
                        value={selectedTimeslotId ?? 'ASAP'}
                        onChange={(e) => handleTimeslotSelect(e)}

                        as="select"
                        // name="timeslot"
                        // value={selectedTimeslotId}
                        // onChange={(e) => handleTimeslotSelect(e)}
                        // isValid={!!values.timeslot && touched.timeslot && !errors.timeslot}
                        // isInvalid={!!errors.timeslot}
                        // custom
                        disabled={disabledSelect}
                        data-testid="select-timeslot"
                        className={'text-ui-font'}
                    >
                        {renderOptions()}
                    </Form.Control>
                    {/*<Form.Control.Feedback type="invalid">{errors.timeslot}</Form.Control.Feedback>*/}
                </div>
                <i className="chev-down feather feather-chevron-down feather-24 brand-color-filter" />
            </StyledDropdown>
        </>
    );
};
const StyledDropdown = styled.div<{ background?: boolean, selectedTimeslot: boolean }>`
    position:relative;
    margin-top: 0px;
    // box-shadow: 0 2px 5px 0 rgb(0 0 0 / 8%);  from Resy
    border-radius: 8px;

    i.chev-down {
        position: absolute;
        top: calc(50% - 11px);
        right: 20px;
        pointer-events: none;
    }

    .dropdown__label {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: var(--brand);
        display: block;
        position: absolute;
        top: 12px;
        left: 48px;
        pointer-events: none;
        user-select: none;
        display: block;
    }

    i.clock {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        user-select: none;
    }

    select {
        background-color: transparent;
        border-radius: 8px;
        color: var(--brand);
        height: 48px;
        line-height: 46px;
        border-color: var(--border-grey);
        cursor: pointer;
        padding: 0 20px 0 46px;
        font-size: 14px;
        outline: none;
        
        font-family: var(--text_ui_font);
        font-weight: var(--text_ui_weight);
        font-size: var(--text_ui_cta);
        text-transform: var(--text_ui_case);
        transition: background-color 0.1s ease-in-out; 
        
        ${(props) => props.selectedTimeslot ? `    
            border-color: var(--brand);
            box-shadow: 0 0 0 1px var(--brand) !important;
        ` : ''}

        &::-ms-expand {
          display: none;
        }

        -moz-appearance: none;
        -webkit-appearance: none;
        
        &:hover {
            background-color: var(--brand-10);
            border-color: var(--brand);
        }
        
        &:focus {
            color: var(--brand) !important;
            box-shadow: 0 0 0 1px var(--brand) !important;
            border-color: var(--brand);
        }
        
        
        color: var(--brand);
        border-color: var(--brand);
        box-shadow: 0 0 0 1px var(--brand) !important;
        border-width: 1px;
        outline: none;
        @media screen and (max-width: 767px) {
            padding-left: 45px;
        }
    
        
    }

`;

export default TimeslotSelect;
