import styled from 'styled-components';
import { t } from 'ttag';
import useOperatorContext from '../../../../../contexts/operator/useOperatorContext';
import useSiteContext from '../../../../../contexts/site/useSiteContext';
import SmallModal from '../../../../general/SmallModal';
import SiteMap from './SiteMap';

type SiteModalProps = {
    onHide: () => void;
    show: boolean;
};

const SiteModal = ({ show, onHide }: SiteModalProps) => {
    const siteContext = useSiteContext();
    const operatorContext = useOperatorContext();
    const header = (
        <>
            <h5 className="d-md-none site-modal-title">{t`Restaurant Info`}</h5>
            <div className="d-md-block d-none">
                <h5 className="mb-2 pr-md-4">{siteContext.site?.name}</h5>
                <p className="mb-0">
                    {siteContext.site?.address_1}, {siteContext.site?.address_city},{' '}
                    {siteContext.site?.address_postcode}
                </p>
            </div>
        </>
    );
    return (
        <StyledModal show={show} onHide={onHide} showClose={true} header={header}>
            <>
                <div className="d-md-none">
                    <h5 className="mb-2 pr-md-4">{siteContext.site?.name}</h5>
                    <p className="mb-0">
                        {siteContext.site?.address_1}, {siteContext.site?.address_city},{' '}
                        {siteContext.site?.address_postcode}
                    </p>
                </div>

                <table className="table">
                    <tbody>
                        {siteContext.site?.opening_hours.map((hours) => (
                            <tr key={'hours_' + hours.day + hours.time}>
                                <td>{hours.day}</td>
                                <td>{hours.time}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <SiteMap />
                <h5>{t`Contact`}</h5>
                <p className="mb-0">
                    {t`Call`} {operatorContext.operator?.name} {t`on`}{' '}
                    <span className="text-primary font-weight-bolder">{siteContext.site?.phone}</span>
                </p>
            </>
        </StyledModal>
    );
};

const StyledModal = styled(SmallModal)`
    .modal-body {
        img {
            width: 100%;
            border-radius: 10px;
            margin-bottom: 16px;
        }
        table {
            table-layout: fixed;
            margin: 1.5rem 0;
            tr td {
                line-height: 30px;
                padding: 0;
                border: 0;
                font-weight: 600;
                &:last-child {
                    font-weight: 400;
                    color: var(--text-black);
                }
            }
        }
    }
    .site-modal-title {
        padding-top: 2px;
        margin-bottom: 0;
    }
`;

export default SiteModal;
