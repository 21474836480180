import styled from "styled-components";

interface CardProps {
    children: JSX.Element,
    icon: string,
    className?: string
}

const InfoCard = ({children, icon, className = ''}: CardProps) => {
    return (
        <StyledCard className={className}>
            {/*<i className={`feather feather-${icon} feather-18`} />*/}
            <div>
                {children}
            </div>
        </StyledCard>
    )
}

const StyledCard = styled.div`
    padding: 0px;
    border-radius: 8px;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    align-items: center;
    i {
        font-size: 20px;
    }
    div {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
    }
    
    @media screen and (min-width: 993px) {
        background-color: transparent;
    }

    &.withBorder {
        padding: 16px;
        border: 1px solid ${(props) => props.theme.color_border};
        margin-bottom: 0;
    }
`;

export default InfoCard;
