import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { Theme as TTheme} from '../contexts/operator/operator-context';
import useOperatorContext from '../contexts/operator/useOperatorContext';
import {iLog} from "../index";


// the default theme is there prior to any operator theme..

const defaultTheme = {
    hero_url: '',
    hero_height: '',
    logo_url: '',
    favicon_url: '',
    background: '',
    background_menu: '',

    color_primary: '#EB5556',
    color_secondary: '#306FDB',
    color_badge: '#306FDB',
    color_badge_icon: '#306FDB',

    h1: {
        font: 'Inter',
        weight: '400',
        size: '36px',
        line: '36px',
        color: '#222',
    },

    h2: {
        font: 'Inter',
        weight: '400',
        size: '28px',
        line: '32px',
        color: '#222',
    },

    h3: {
        font: 'Inter',
        weight: '400',
        size: '24px',
        line: '32px',
        color: '#222',
    },

    h4: {
        font: 'Inter',
        weight: '400',
        size: '18px',
        line: '32px',
        color: '#222',
    },

    card: {
        border: '1px solid #EB5556',
        radius: '8px',
        shadow: 'none'
    },

    cta: {
        font: 'Inter',
        size: '20px',
        weight: '400',
        bg: '#00CD00',
        color: '#FFFFFF',
        hover: '#FAFAFA',
        disabled: '#E2E2E5',
    },

    cta2: {
        bg: '#FAFAFA',
        color: '#FFFFFF',
        disabled_bg: '#E2E2E5',
        disabled_color: '#E2E2E5',
    },

    nav_bg: '#FFFFFF',
    nav_border: '1px solid #E7E7E7',

    bodyFont: {
        name: 'Inter',
        size: '15',
        ttf: null,
        woff: null,
        weight: '400',
    },
    font: {
        name: 'P22 Underground',
        size: '',
        ttf: 'https://s3.eu-west-2.amazonaws.com/orderswift-production/fonts/iH70BFvKAohbcMAkW16l2bY2vmooo7Ih.ttf',
        woff: 'https://s3.eu-west-2.amazonaws.com/orderswift-production/fonts/zqoqgurZ99PosR5eVkiPScHKrYjneb3T.woff',
        woff2: 'https://s3.eu-west-2.amazonaws.com/orderswift-production/fonts/BMuDTUeKUHDllmQ93v8T1OKTOiy8IozB.woff2',
        weight: '600',
    },

    fonts: [],

    css_mobile_header_bg: 'color',
    css_bg_image: 'stretch',
    css_shadows: 'hide',
    css_custom: null,


    // new bits
    slogan: 'WELCOME TO ORDERSWIFT',
    nav_variant: 'lines',
    nav_count: false,

    menu_item_variant: 'classic',


    // old bits to delete?

    color_primary_shade: '#F8E9EA',

    color_secondary_shade: '#E6ECF7',
    color_bg: '#FFFFFF',
    color_banner: '#876d4d',
    color_border: '#E7E7E7',

    // Orderswift colours
    color_os_red: '#E62123',
    color_os_blue: '#2BBADC',


    // Typography variables
    color_shade_0: '#FFFFFF',
    color_shade_1: '#000000',
    color_shade_2: '#2A2A2A',
    color_shade_5: '#F1F1F4',

    // State variables
    color_success: '#459473',
    color_success_shade: '#F5FEF0',
    color_danger_shade: '#FFE7E9',
    color_warning: '#B04918',
    color_warning_shade: '#FFF6D2',
    color_info: '#3A71C5',
    color_info_shade: '#DDEBFD',

    v3:
        {
            logo_url: '',
            logo_height: '36px',
            favicon_url: '',

            fonts: [],

            layouts: {
                headers: {
                    primary_bg: '',
                    secondary_bg: '',
                    borders: {
                        channels: false,
                        sites: false,
                        menu: false,
                    }
                },
                hero: '',
                body: {
                    channel_select_bg: '',
                    site_select_bg: '',
                    menu_bg: '',
                }
            },

            ui: {
                nav: {
                    channels: '',
                    menu: {
                        bg: '',
                        color: '',
                        border: '',
                    }
                },
                banner: {
                    bg: '',
                    color: ''
                },
                buttons: {
                    cta: {
                        bg: '',
                        color: ''
                    },
                    cta_disabled: {
                        bg: '',
                        color: ''
                    },
                    cta_alt: {
                        border: '',
                        color: ''
                    },
                    cta_basket: {
                        bg: '',
                        color: ''
                    },
                    change_location: {
                        bg: '',
                        color: '',
                        size: '14px'
                    }
                },
                other: {
                    link: '',
                    badge: ''
                },
                tiles: {
                    radius: '8px'
                }
            },
            text: {
                display: {
                    font: '',
                    weight: '',
                    spacing: '',
                    case: '',

                    h1: {
                        height: '',
                        color: '',
                    },

                    h2: {
                        height: '',
                        color: '',
                    },

                    h3: {
                        height: '',
                        color: '',
                    },

                    h4: {
                        height: '',
                        color: '',
                    }
                },
                ui: {
                    font: '',
                    weight: '',
                    spacing: '',
                    case: '',

                    cta: '',
                    select_channel_nav: '',
                    menu_nav: ''
                }
            },

            language: {
                slogan: '',
                opt_in: ''
            },

            ux: {
                menu_nav: '',
                menu_layout: '',
                menu_tiles: '',
                filters: true,
                nearest: true,
            },

            extra_css: '',
            default_opt_in: false,

            tipping: {
                collection: false,
                delivery: false,
                dine_in: false,
            }
        }
};

type PageProps = {
    children: JSX.Element;
};

const Theme = ({children}: PageProps) => {
    const operatorContext = useOperatorContext();
    const [theme, setTheme] = useState<TTheme>(defaultTheme);

    useEffect(() => {
        if (operatorContext.operator) {

            let operator_theme = operatorContext.operator?.theme;

            let obj = {
                ...defaultTheme,
                ...operator_theme,
                fonts: operator_theme?.v3.fonts ?? [],
                color_bg: String('#FFFFFF'),
            };

            setTheme(obj);
        }
    }, [ operatorContext ]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
