import React from 'react';
import styled from 'styled-components';
import { TPage } from '../AppLayout';
import useScrollPosition from '../../../../hooks/useScrollPosition';


type BGProps = {
    children: JSX.Element | JSX.Element[];
    page: TPage;
};

const Header = ({ children, page }: BGProps) => {
    const scrollPosition = useScrollPosition();

    return (
        <>
            <StyledCard page={page} className={`styledCard ${scrollPosition > 0 ? 'scrolled' : ''} ${page==='menu' ? 'd-none' : ''}`}>
                {page === 'channels' && (
                    <>
                        {/*<BrandLogoHeaderContainer className='brandLogoHeaderContainer'>
                            <BrandLogo src={logo} />
                            <div className="divider"></div>
                        </BrandLogoHeaderContainer>*/}
                        <div className="fullSizedBg" />
                        {/* <PoweredBy src={powered_by} /> */}
                    </>
                )}
                {children}
            </StyledCard>
        </>
    );
};

const StyledCard = styled.div<{ page: TPage }>`
    display: flex;
    flex-direction: column;
    height: auto;
    background: no-repeat center center;
    background-size: cover;
    background-color: #ffffff;

     ${(props) =>
    props.page === 'channels'
        ? `
            background-size: cover;
            /*height: calc(100vh - 72px);
            margin-top: 72px;*/
            height: 100vh;
            width: 50%;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            @media screen and (min-width: 2000px) {
                width: 50%;
            }
    `
        : null}

    ${(props) =>
        props.page === 'menu' || props.page === 'confirmation'
            ? `
            display: none
            `
            : null }

    /* ${(props) =>
        props.page === 'checkout' ? 'height: 236px; display:none; @media(min-width: 768px){display:block}' : null} */

    &.scrolled {}

    @media screen and (max-width: 767px) {
        ${(props) =>
            props.page === 'channels'
                ? `
            position: relative;
            /*height: calc(229px + 32px);  todo: reversible */
            margin-top: 56px;
            width: auto;
            overflow: hidden;
            background-color: #FFFFFF;

            padding: 0;
            max-height: calc(100vw * 0.75);  // 4:3 ratio
            height: calc(100vw * 0.75);


        ` : null}
        /*padding: 16px;
        border-radius: 4px;
        todo: reversible
        */


        z-index: 25;
    }

    .fullSizedBg {
        background-color: #FAFAFA;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-image: var(--layouts_hero);

        @media screen and (max-width: 767px) {
            height: calc(100vw * 0.75);
        }
    }
`;

export default Header;
