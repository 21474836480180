import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { t } from 'ttag';
import SiteRoutes from './SiteRoutes';
import GenericErrorPage from "./Error/GenericErrorPage";

const PageRoutes = () => {
    return (
        <Routes>
            <Route
                path={`/404`}
                element={
                    <GenericErrorPage
                        title={t`Sorry, this page was not found.`}
                        message={t`Go back to the <a href="/" class="cursor-pointer">homepage</a> or contact us if the issue persists.`}
                    />
                }
            />
            <Route
                path={`/500`}
                element={
                    <GenericErrorPage
                        title={t`Sorry, there was an unexpected error.`}
                        message={t`Please try again or contact us if the issue persists.`}
                    />
                }
            />
            <Route path={`/*`} element={<SiteRoutes />} />

            {/* <Route path="*">
                <ErrorPage />
            </Route> */}
        </Routes>
    );
};

export default PageRoutes;
