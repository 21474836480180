import React from 'react';
import styled from 'styled-components';
import { readableColor, darken, rgba } from 'polished';

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
} & CTAProps;

export interface CTAProps {
    block?: boolean;
    type?: 'submit' | 'reset' | 'button';
    secondary?: boolean;
    small?: boolean;
    dual?: boolean;
    buddies?: boolean;
    jumbo?: boolean;
    cart?: boolean;
}

const Button = (props: ButtonProps) => {
    return (
        <Wrapper {...props} className={`font-cta text-ui-font ${ (props.className ?? 'default') }`}>
            {props.children}
        </Wrapper>
    );
};

interface WrapperProps {
    block?: boolean;
    secondary?: boolean;
    small?: boolean;
    dual?: boolean;
    buddies?: boolean;
    jumbo?: boolean;
    cart?: boolean;
}

const Wrapper = styled.button<WrapperProps>`
    
    background-color: var(--ui_buttons_cta_bg);
    transition: background-color 0.2s ease-in-out;
    
    color: var(--ui_buttons_cta_color);
    font-family: var(--text_ui_font);
    font-weight: var(--text_ui_weight);
    font-size: var(--text_ui_cta);
    text-transform: var(--text_ui_case);
    
    line-height: 24px;
    
    height: 48px;
    padding: 16px 24px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: auto;
    
    border: none;
    border-radius: 8px;
    
    &.disabled, &[disabled], &.disabled:hover, &[disabled]:hover {
        background: var(--btn-grey);
        color: white;
    }
    
    &:hover { background-color: ${(props) => darken(0.05, props.theme.v3.ui.buttons.cta.bg)}; }
    &:active { background-color: ${(props) => darken(0.1, props.theme.v3.ui.buttons.cta.bg)}; }
    &:focus { outline: none; }
    
    
    @media screen and (max-width: 767px){
        width: 100%;
    }
    
    
    ${(props) => props.block && `
        width: 100%;
    `}
    
    ${(props) => props.disabled && 'cursor: not-allowed;'}
    
    ${(props) => props.small && `
        height: 40px;
        padding: 10px 24px;
        font-size: 16px;
        line-height: 20px;
    `}
    
    ${(props) => props.secondary && `
        background: white;
        border: 1px solid var(--ui_buttons_cta_alt_border);
        color: var(--ui_buttons_cta_alt_color);
    
        &:hover {
            background-color: ${rgba(props.theme.v3.ui.buttons.cta_alt.color, 0.1)};
        }
    `}
    
    ${(props) => props.dual && `
        justify-content: space-between;
        
        @media screen and (max-width: 767px){
            padding: 0 16px;
        }
    `}
    
    ${(props) => props.cart && `
        &.disabled, &[disabled], &.disabled:hover, &[disabled]:hover {
            background: var(--ui_buttons_cta_disabled_bg);
            color: var(--ui_buttons_cta_disabled_color);
        }
        &.disabled .basket-btn-divider, 
        &[disabled] .basket-btn-divider, 
        &.disabled:hover .basket-btn-divider, 
        &[disabled]:hover .basket-btn-divider {
            border-color: var(--ui_buttons_cta_disabled_color) !important;
        }
        
        background: var(--ui_buttons_cta_basket_bg);
        color: var(--ui_buttons_cta_basket_color);
        
        &:hover { background-color: ${ darken(0.05, props.theme.v3.ui.buttons.cta_basket.bg) }; }
        &:active { background-color: ${ darken(0.1, props.theme.v3.ui.buttons.cta_basket.bg) }; }
    `}
    
    ${(props) => props.buddies && `flex: 1 1 0%;`}
    
    
    
    
`;

export const CTA = styled(Button)``;
























export const MainButton = styled(Button)`
    background-color: var(--cta-bg);
    color: var(--cta-color);
    font-family: var(--cta-font);
    font-weight: var(--cta-weight);
    font-size: var(--cta-size);
    
    text-transform: uppercase;
    
    height: 48px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    &.disabled {
        background: #E7E7E7;
        color: white;
    }

    @media screen and (max-width: 575px){
        padding: 0 16px;
        font-size: 16px;
    }

    &:hover {
        background-color: ${(props) => darken(0.05, props.theme.color_secondary)};
    }
    &:active {
        background-color: ${(props) => darken(0.1, props.theme.color_secondary)};
    }
    &:focus {
        outline: none;
        /*box-shadow: 0px 0px 0px 4px ${(props) => rgba(props.theme.color_secondary, 0.1)};*/
    }
    &:disabled {
        color: #fff;
    }
`;

export const SecondaryButton = styled(Button)`
    background-color: #FFFFFF;
    color: var(--brand);
    font-family: var(--cta-font);
    font-weight: var(--cta-weight);
    font-size: var(--cta-size);
    text-transform: uppercase;
    height: 48px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--brand);
    
    &:hover {
        background-color: ${(props) => darken(0.1, '#FFFFFF')};
    }
    &:active {
        background-color: ${(props) => darken(0.15, '#FFFFFF')};
    }
    &:focus {
        outline: none;
    }
`;

export const BrandButton = styled(Button)`
    background-color: ${(props) => props.theme.color_primary};
    color: ${(props) => readableColor(darken(0.2, props.theme.color_primary))};
    &:hover {
        background-color: ${(props) => darken(0.1, props.theme.color_primary)};
    }
    &:active {
        background-color: ${(props) => darken(0.15, props.theme.color_primary)};
    }
    &:focus {
        outline: none;
        /*box-shadow: 0px 0px 0px 4px ${(props) => rgba(props.theme.color_primary, 0.1)};*/
    }
`;

export const SiteButton = styled(Button)`
    font-size: var(--cta-size);
    font-family: var(--text_ui_font);
    letter-spacing: var(--text_ui_spacing);
    text-transform: var(--text_ui_case);
    padding: 8px 0;
    font-weight: var(--text_ui_weight);
    width: 100%;
    float: right;
    background: var(--ui_buttons_cta_bg);
    color: var(--ui_buttons_cta_color);
    padding: 10px 20px;
    border-radius: 6px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    &:hover {
        background-color: ${(props) => darken(0.05, props.theme.color_primary)};
    }
    &:active {
        background-color: var(--brand);
    }
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:disabled {
        background: var(--bg-grey);
        pointer-events: none;
    }

    @media screen and (min-width: 768px) {
        width: auto;
    }
`;

export const SiteButtonClosed = styled(SiteButton)`
    background: white;
    border: 1px solid ${(props) => props.theme.color_primary};
    color: ${(props) => props.theme.color_primary};

    &:hover {
        background-color: ${(props) => props.theme.color_primary};
        color: white;
    }
`;

export const LinkButton = styled(Button)<{ underline?: boolean; color?: string; tight?: boolean }>`
    font-size: var(--core_text_ui_lg);
    padding: 0 3px;
    border-radius: 6px;
    color: ${(props) => (props.color ? props.color : props.theme.color_primary)};
    ${(props) => (props.underline ? 'text-decoration: underline;' : null)}
    ${(props) => (props.tight ? 'margin: 0 -3px;' : null)}
    &:hover {
        background-color: ${(props) => (props.color ? rgba(props.color, 0.1) : rgba(props.theme.color_primary, 0.05))};
    }
    &:active {
        background-color: ${(props) => (props.color ? rgba(props.color, 0.15) : rgba(props.theme.color_primary, 0.1))};
    }
    &:focus {
        outline: none;
        background-color: ${(props) => (props.color ? rgba(props.color, 0.1) : rgba(props.theme.color_primary, 0.05))};
        box-shadow: 0px 0px 0px 2px
            ${(props) => (props.color ? rgba(props.color, 0.3) : rgba(props.theme.color_primary, 0.2))};
    }
    @media screen and (min-width: 576px) {
        font-size: var(--core_text_ui_lg_desktop)
    }
`;

export const StickyDiscountButton = styled(Button)`
    padding: 10px 16px;
    font-size: 14px;
    background: ${props => props.theme.color_info};
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);
    border-radius: 180px;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
`;

export const PrimaryModalButton = styled(MainButton)`
    width: 100%;
    margin: 0;
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 6px;
`;

export const SecondaryModalButton = styled(SiteButtonClosed)`
    width: 100%;
    margin: 0;
    flex: 1;
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
    padding: 0 24px;
    height: 48px;
`;


export default Button;
