export const money = (amount: number, ccy = 'GBP', locale = 'en-GB') => {
    let formatter = new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: ccy,
    });

    return formatter.format(amount / 100);
};

export const capitalizeFirstLetter = (string: string) => {
    return string[0].toUpperCase() + string.slice(1);
};
