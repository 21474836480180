import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from '../contexts/app/AppContextProvider';
import BasketContextProvider from '../contexts/basket/BasketContextProvider';
import CustomerContextProvider from '../contexts/customer/CustomerContextProvider';
import OperatorContextProvider from '../contexts/operator/OperatorContextProvider';
import OrderContextProvider from '../contexts/order/OrderContextProvider';
import SiteContextProvider from '../contexts/site/SiteContextProvider';
import '../styles/App.css';
import Router from './pages/Routes';
import Theme from './Theme';

const App = () => {
    return (
        <HelmetProvider>
            <BrowserRouter>
                <AppContextProvider>
                    <CustomerContextProvider>
                        <OperatorContextProvider>
                            <Theme>
                                <SiteContextProvider>
                                    <BasketContextProvider>
                                        <OrderContextProvider>
                                            <Router />
                                        </OrderContextProvider>
                                    </BasketContextProvider>
                                </SiteContextProvider>
                            </Theme>
                        </OperatorContextProvider>
                    </CustomerContextProvider>
                </AppContextProvider>
            </BrowserRouter>
        </HelmetProvider>
    );
};

export default App;
