import { useContext } from 'react';
import { CustomerContext } from './customer-context';

const useCustomerContext = () => {
    const context = useContext(CustomerContext);

    if (context === undefined) {
        throw new Error('useCustomerContext must be within a CustomerProvider');
    }

    return context;
};

export default useCustomerContext;
