import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import AppLayout from '../../layouts/app/AppLayout';
import {IconName} from "@fortawesome/fontawesome-svg-core";

type ErrorPageProps = {
    title: string;
    message: string;
    //icon: IconName;
};

const ErrorPage = ({ title, message }: ErrorPageProps) => {
    return (
        <AppLayout page="menu">
            <Wrapper>
                <MessageWrapper>
                    {/*<FontAwesomeIcon icon={icon} size="5x" />*/}
                    <h2 className="my-4">{title}</h2>
                    <p dangerouslySetInnerHTML={{ __html: message }} />
                </MessageWrapper>
            </Wrapper>
        </AppLayout>
    );
};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MessageWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 15px;
    background-color: var(--layouts_body_menu_bg);
    padding: 2rem 1rem;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
`;

export default ErrorPage;
