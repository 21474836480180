import { t } from 'ttag';
import { TChannel } from '../contexts/operator/operator-context';

type ChannelConfig = {
    collection: ChannelConfigSettings;
    delivery: ChannelConfigSettings;
    dineIn: ChannelConfigSettings;
    'dine_in': ChannelConfigSettings;
};

export type ChannelConfigSettings = {
    name: TChannel;
    handle: string;
    displayName: string;
    inPerson: boolean;
};

const config: ChannelConfig = {
    collection: {
        name: 'collection',
        handle: 'collection',
        displayName: t`Collection`,
        inPerson: true,
    },
    delivery: {
        name: 'delivery',
        handle: 'delivery',
        displayName: t`Delivery`,
        inPerson: false,
    },
    dineIn: {
        name: 'dine_in',
        handle: 'at-table',
        displayName: t`At Table`,
        inPerson: true,
    },
    'dine_in': {
        name: 'dine_in',
        handle: 'at-table',
        displayName: t`At Table`,
        inPerson: true,
    },
};

export default config;
