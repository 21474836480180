import React from 'react';
import styled from 'styled-components';
import { TPage } from '../AppLayout';

type CardProps = {
    children: JSX.Element | JSX.Element[];
    page: TPage;
};

const Content = ({ children, page = null }: CardProps) => {
    return (
        <StyledContainer page={page}>
            {children}
            <Background page={page} />
        </StyledContainer>
    );
};

const Background = styled.div<{ page: TPage }>`
    /* overflow: auto; */
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #FAFAFA;
    background-color: ${(props) => (props.page === 'checkout' || props.page === 'confirmation') && '#ffffff'};

    @media screen and (max-width: 575px) {
        background-image: none;
    }

    @media screen and (max-width: 767px) {
        background-color: white;
    }
`;

const StyledContainer = styled.div<{ page: TPage }>`
    display: block;
    margin: 0 auto;
    position: relative;
    max-width: 100%;
    background: var(--layouts_body_channel_select_bg);
    background-position-y: 48px;
    min-height: calc(100vh - 56px);
    ${(props) =>
        props.page === 'channels'
            ? `
        max-width: none;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        width: 50%;
        min-width: 720px;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        /* min-height: calc(100vh - 325px); */

        min-height: 100vh;


        @media screen and (max-width: 767px){
            width: 100%;
            height: auto;
            min-width: 0;
            max-width: none;
        }

    `
            :  null}

    ${(props) => props.page === 'checkout' || props.page === 'confirmation' ? `
        background: #FFFFFF !important;
        padding-top: 64px;
        padding-left: 48px;
        padding-right: 48px;

        @media screen and (max-width: 992px){
            padding-left: 0px;
            padding-right: 0px;
        }
    ` : ''}


    /* ${(props) =>
        props.page === 'menu'
            ? `
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        max-width: 1140px;
        @media screen and (min-width: 1140px){
            border-radius: 0;
            display: flex;
            padding: 15px;
        }
    `
            : null} */

    ${(props) => props.page === 'menu' && `
        @media screen and (max-width: 767px){
            background-color: #FFFFFF;
        }
        max-width: none;
    `}

    @media screen and (max-width: 992px) {
        ${(props) => props.page === 'sites' ? `width: auto;` : null}

    }
`;

export default Content;
