import React, { useEffect } from 'react';
import {Modal } from 'react-bootstrap';
import styled from 'styled-components';
import mixpanel from "mixpanel-browser";
import TinyModal from "../../../../../general/TinyModal";

type AllergensModalProps = {
    show: boolean;
    onHide: () => void;
    children?: JSX.Element
};

const AllergensModal = ({
    show,
    children,
    onHide,
}: AllergensModalProps) => {


    return (
        <TinyModal
            show={show}
            backdropClassName='modal-item-allergens-backdrop'
            onHide={onHide}
            modalID={'leaving-modal'}
        >
            <>
                <h4 className='modal__title'>Allergen Info</h4>
                {children}
            </>
        </TinyModal>
    );
};


export default AllergensModal;
