import React from 'react';
import styled, {keyframes} from 'styled-components';
import {Theme} from "../../../../../../../contexts/operator/operator-context";
import {rgba} from "polished";
import mixpanel from "mixpanel-browser";

interface QuantityButtonProps {
    quantity: number;
    setQuantity: (quantity: number) => void;
    type: 'large' | 'small';
    minimum?: boolean
}

const QuantityButton = ({ quantity, setQuantity, type, minimum = false }: QuantityButtonProps) => {
    return (
        <QuantityButtonWrapper className='quantity-btn' type={type}>
            <button
                onClick={(e) =>  {
                    e.stopPropagation();
                    if ( quantity > 1 || !minimum ) {
                        mixpanel.track('Set quantity (-)');
                        setQuantity(quantity - 1)
                    }
                    return null;
                }}
                className={!minimum || quantity > 1 ? '' : 'disabled'}
                disabled={minimum && quantity <= 1}
                data-testid="dec-item-qty"
            >
                <i className={"feather feather-18 feather-minus-circle"} />
            </button>
            <span className='quantity' data-testid="val-item-qty">
                <span>{quantity}</span>
            </span>
            <button onClick={(e) => {
                e.stopPropagation();
                mixpanel.track('Set quantity (+)');
                setQuantity(quantity + 1)
            }} data-testid="inc-item-qty">
                <i className="feather feather-plus-circle feather-18" />
            </button>
        </QuantityButtonWrapper>
    );
};

const QuantityButtonWrapper = styled.div<{type: 'large' | 'small'}>`
    display: flex;
    align-items: center;
    flex-grow: 0;
    gap: 4px;
    margin: 0;
    padding: 0;
    @media screen and (min-width: 768px) {
        padding: 0 30px;
    }
    .quantity {
        margin: 0 auto;
        font-weight: 400;
        font-size: var(--core_text_ui_lg);
        line-height: 24px;
        color: var(--text-black);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            font-weight: 500;
            line-height: 22px;
            font-size: 16px;
            width: 24px;
            text-align: center;
        }
    }
    button {
        outline: none;
        background-color: transparent;
        height: 16px;
        width: 16px;
        position: relative;
        border: 0;
        padding: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--brand);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
        &.disabled {
            pointer-events: none;
            color: var(--border-grey);
        }
        &:not(.quantity-btn__trash):hover {
            background-color: var(--brand-10);
        }
    }

    .quantity-btn__trash {
        color: var(--text-grey);
    }

    ${(props) => props.type === "large" ? `
        gap: 12px;
        .quantity {
            border: 1px solid var(--border-grey);
            border-radius: 8px;
            height: 48px;
            width: 48px;
            span {
                width: 32px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }
        }
        button {
            height: 20px;
            width: 20px;
        }
    ` : ''}
`;

export default QuantityButton;
