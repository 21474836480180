import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../contexts/basket/useBasketContext';
import useOrderContext from '../../contexts/order/useOrderContext';
import useSiteContext from '../../contexts/site/useSiteContext';
import { MainButton } from '../general/Button';
import SmallModal from '../general/SmallModal';
import TimeslotSelect from './TimeslotSelect';
import {useStateRef} from "../../hooks/usestateref";
import {OrderData} from "../../contexts/order/order-context";
import {iLog} from "../../index";

type EatInModalProps = {
    onHide: () => void;
    show: boolean;
    type?: 'unavailable' | 'change';
};

const TimeslotModal = ({ onHide, show, type = 'unavailable' }: EatInModalProps) => {
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        // console.debug('TimeslotModal', [
        //     basketContext.validatedSite,
        //     siteContext.site,
        //     !siteContext.timeslots?.length,
        //     basketContext.validatedSite && !siteContext.timeslots?.length,
        //     show
        // ]);

        // if (basketContext.validatedSite && !siteContext.timeslots?.length) {
        if (
            (siteContext.site || basketContext.validatedSite)
            && !siteContext.timeslots?.length
            || show
        ) {
            iLog('timeslot useEffect - calling fetch timeslot', [siteContext.site, basketContext.validatedSite]);

            // make api request for timeslot
            // alert('timeslot api call');
            setLoading(true);

            siteContext.fetchTimeslots(
                siteContext.site?.uid ?? basketContext.validatedSite?.uid ?? '',
                siteContext.flow);

            setLoading(false);


        }

    }, [siteContext.site, show]);


    const handleTimeslotSave = async () => {
        setLoading(true);

        // if same as validated timeslot, do nothing
        // if (basketContext.validatedBasket?.ready_time.id === orderContext.timeslot?.id) {
        //     console.warn('Timeslot was not changed. Skipping validation.');
        //     return;
        // }

        // timeslot validation
        try {
            await basketContext.validateTimeslot();
        } catch (error) {
            console.error('Error revalidating the basket after timeslot issue.', error);
            setLoading(false);
        }

        // if this fails at this stage, do we return them back to the site?

        setLoading(false);
        onHide();
    };

    const header = <h5>{t`Select a new time slot`}</h5>;

    const footer = (
        <>
            <MainButton
                block
                //className="mr-3"
                onClick={handleTimeslotSave}
                disabled={loading}>
                    Done
            </MainButton>
            {/* <SecondaryButton onClick={onHide} disabled={loading} block>Cancel</SecondaryButton> */}
        </>
    );

    return (
        <StyledModal header={header} show={show} onHide={onHide} expandMobile={false} footer={footer}>
            <>
                {type == 'unavailable' ? (
                    <p>{t`The time slot you chose is no longer available. Please select another`}</p>
                ) : (
                    <p>{t`Please select a time slot for your order`}</p>
                )}
                <TimeslotSelect
                    timeslots={siteContext.timeslots}
                    selectedTimeslotId={basketContext.timeslot?.id}
                />
            </>
        </StyledModal>
    );
};

const StyledModal = styled(SmallModal)``;

export default TimeslotModal;
