import React from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import styled  from 'styled-components';
import config from '../../../../config/channels';
import { TChannel } from '../../../../contexts/operator/operator-context';
import useOperatorContext from '../../../../contexts/operator/useOperatorContext';
import NavBadge from '../../../general/NavBadge';
import mixpanel from "mixpanel-browser";



type ChannelSelectorProps = {
    children: JSX.Element | JSX.Element[];
    enabledChannels: TChannel[];
    selectedChannel: string;
    setActiveChannelTab: React.Dispatch<React.SetStateAction<string>>;
};

const ChannelSelector = ({
    children,
    selectedChannel,
    enabledChannels,
    setActiveChannelTab
}: ChannelSelectorProps) => {
    const navigate = useNavigate();
    const operatorContext = useOperatorContext();

    /*const isChannelEnabled = (channel: TChannel) => {
        return !!enabledChannels.find((c) => c === channel);
    };*/

    //const renderChannelNav = (channel: TChannel, displayName: string, handle: string, selected: string) => {
    const renderChannelNav = (channel: TChannel, selected: string, enabledChannels: number) => {
        /*if (!isChannelEnabled(channel)) {
            return null;
        }*/

        /*if (enabledChannels.length === 1) {
            return (
                <h2 className="text-center mt-3">
                    {displayName} {t`with`} <br />
                    {operatorContext.operator?.name}
                </h2>
            );
        }*/

        return (
            <NavBadge size={'md'} key={config[channel]['handle']}>
                <Nav.Link
                    className={`text-ui-font ${selected === channel ? 'active' : undefined} ${enabledChannels === 1 ? 'nav-link--solo' : ''}`}
                    eventKey={`/${config[channel]['handle']}`}
                    /*onClick={() => {
                        mixpanel.track('Selected channel ' + config[channel]['handle']);
                        navigate(`/${config[channel]['handle']}`);
                    }}*/
                    onClick={() => selectChannel(channel)}
                >
                    {config[channel]['displayName']}
                </Nav.Link>
            </NavBadge>
        );
    };

    const selectChannel = (channel: TChannel) => {
        mixpanel.track('Selected channel ' + config[channel]['handle']);

        //setActiveChannelTab(channel);
        navigate(`/` + config[channel]['handle']);
    };

    return (
        <ChannelSelectWrapper>
            <StyledContainer className={'channel-select-container'}>
                <StyledNavBrand onClick={() => navigate('/')} className="logoClass" />

                <Heading className="text-left"
                         dangerouslySetInnerHTML={{ __html: operatorContext?.operator?.theme.v3.language.slogan ?? '' }} />

                <StyledNav
                    soloChannel={enabledChannels.length === 1}
                    className="channel-select-nav"
                    // variant={operatorContext?.operator?.theme.v3.text.ui.menu_nav ?? 'lines'}
                    variant="buttons"
                    defaultActiveKey={`/${selectedChannel}`}
                >
                    { enabledChannels.map((item, index) => {
                        return renderChannelNav(item, selectedChannel, enabledChannels.length)
                    })}
                </StyledNav>
                <>{children}</>
            </StyledContainer>
        </ChannelSelectWrapper>
    );
};

const ChannelSelectWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 64px;
    padding-bottom: 32px;
    overscroll-behavior-y: none;
    @media screen and (max-width: 767px){
        width: 100%;
        margin-top: 0;
    }
    
    @media screen and (min-width: 768px){
        min-height: 100vh;
        // offset bottom (56px nav + 24px existing padding = 80px)
    }
    @media screen and (min-width: 2000px) {
        display: block;
        padding-top: 164px;
    }
`;

const StyledContainer = styled.div`  
    width: 100%;
`;

const StyledNav = styled(({ soloChannel, ...props }) => <Nav {...props} />)`
    padding: 0;
    top: 0;
    z-index: 1;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 0 48px;
    
    // .nav-item {
    //     &:nth-child(2) {
    //         margin-left: 0px;
    //         margin-right: -1px;
    //     }
    // }
    
    @media screen and (max-width: 767px){   
        margin: 0;
    }

   &.nav-lines {
        // padding-bottom: 0;
        // @media screen and (max-width: 767px) {
        //     justify-content: start;
        //     display: flex;
        // }
        // @media screen and (max-width: 452px) {
        //     gap: 6px;
        // }
        
    //    full width
    
        display: flex;
    }
    
     &.nav-buttons {
        padding-bottom: 0;
        @media screen and (max-width: 767px) {
            overflow-x: auto;
            ::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
            ::-webkit-scrollbar-thumb {
                background: transparent;
            }
        }
        @media screen and (max-width: 767px){   
            .nav-item:first-child {
                margin-left: 20px;
            }
        }
    }
    
    
    
    ${(props) =>
        props.soloChannel
            ? null
            : `@media screen and (max-width: 392px) {
            overflow-x: auto;
            overflow-y: hidden;
            display: block;
            white-space: nowrap;
        }`}

    @media screen and (max-width: 767px) {
        position: relative !important;
        z-index: 1 !important;
        // justify-content: space-between;
        display: flex;
        
    }



`;

const Heading = styled.h1`
    margin-bottom: 48px;
    padding: 0 48px;
    padding-top: 48px;
    @media screen and (max-width: 767px) {
        padding: 0 20px;
        margin-top: 32px;
        margin-bottom: 32px;
    }
`;

const StyledNavBrand = styled.div`
    background-image:  url(${(props) => props.theme.logo_url});
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
    height: 48px;
    margin: 0;
    background-position: left;
    width: 290px;
    max-width: 85vw;
    display: block;
    cursor: pointer;
    margin-bottom: 134px;
    
    display: none;
`;

export default ChannelSelector;
