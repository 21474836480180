import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import mixpanel from "mixpanel-browser";


type HelpModalProps = {
    show: boolean;
    onHide: () => void;
    siteTel: string;
    provider: string;
    providerTel: string;
    providerLink: string;
};

const HelpModal = ({
    show,
    onHide,
    siteTel,
    provider,
    providerTel,
    providerLink
}: HelpModalProps) => {

    useEffect(() => {

        document.getElementById('leaving-help-modal')?.classList.add('anim-enter');
        if ( show ) {
            mixpanel.track('viewed help');
        }
        return () => {
            document.getElementById('leaving-help-modal')?.classList.remove('anim-enter');
        };

    }, [show]);



    return (
        <HelpModalWrapper
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            centered
            animation={false}
            id={'leaving-help-modal'}
        >
            <Modal.Header className="">
                <h4 className='modal__title'>Order Help</h4>
                <div className="modal-close__wrapper">
                    <button role="button" className={'modal-close__btn'} onClick={() => onHide()}>
                        <i className="feather feather-x feather-18 brand-color-filter" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body-container">
                    { providerTel.length > 0 ? (<p>If there is an issue with your delivery, please contact {provider} at {providerTel}.</p>) : null }
                    { providerLink.length > 0 ? (<p>If there is an issue with your delivery, please contact <a href={providerLink} rel='noreferrer' target="_blank">{provider}</a>.</p>) : null }
                    <p>If there is a problem with your food, please contact the restaurant at <a href={'tel:' + siteTel.replaceAll(' ', '')}>{siteTel}</a>.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer className="">

                <div className="footerContent">

                </div>
            </Modal.Footer>
        </HelpModalWrapper>
    );
};

const HelpModalWrapper = styled(Modal)`
    .modal-body {
        overflow-y: auto;

        .modal-body-container {
            padding: 20px;
            margin-top: 64px;
            
            p {
                margin-bottom: 20px;
            }
        }

    }
    .modal-header {
        display: flex;
        align-items: center;
        > div { width: 100%}
        background-color: white;
        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 16px 20px;
        min-height: 64px;

        .modal__title {
            margin-bottom: 0;
            transition: opacity 0.2s ease-in-out;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 40px;
        }
    }

    .modal-body {
        padding: 0;
        
        h2 {
            margin-top: 16px;
        }
    }



    .close {
        float: left;
        opacity: 1;
    }



    .modal-footer {
        padding: 16px 20px !important;
        box-shadow: none !important;

        .footerContent {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            flex-direction: column;
            gap: 16px;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }

    }
    .modal-dialog .modal-content {
        border-radius: 4px;
        max-width: 560px;
        height: auto;
        margin: 20px;
    }

`;

export default HelpModal;
