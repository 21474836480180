import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import mixpanel from "mixpanel-browser";
import TinyModal from "../../../general/TinyModal";


type TermsModalProps = {
    show: boolean;
    onHide: () => void;
};

const TermsModal = ({
    show,
    onHide,
}: TermsModalProps) => {

    useEffect(() => {
        document.getElementById('leaving-modal')?.classList.add('anim-enter');
        if ( show ) {
            mixpanel.track('viewed terms');
        }
        return () => {
            document.getElementById('leaving-modal')?.classList.remove('anim-enter');
        };
    }, [show]);


    return (
        <TinyModal
            show={show}
            onHide={onHide}
            modalID={'terms-modal'}
        >
            <>
            <h4 className='modal__title'>Terms and Conditions</h4>
            <h2>Introduction</h2>
            <p>These are the terms and conditions of use for orderswift ordering and payment service  allowing you to order and pay for food online from your selected restaurant, eatery, café, deli, pub or food truck from the relevant restaurant's website (Service). The Service is provided by Tasty Tech Limited trading as orderswift (we, us and our).  We are a limited company, registered in England.  Our registered company number is 08467443, and our registered office is 1 Honeypot Farm, Honeypot Lane, Edenbridge, Kent, TN8 6QH. Our VAT registration number is 162049524</p>

            <p>Your use of the Service will be subject to these terms and conditions and by placing orders on and making payments through the Service you agree to be bound by them.</p>

            <p>We reserve the right to change these terms and conditions from time to time by changing them on the Service.  These terms and conditions were last updated on 27th April 2020.</p>

            <h2>What we do</h2>
            <p>The Service allows you to order and pay for food online from your selected restaurant, eatery, cafe, deli, pub or food truck (Restaurants) from the Restaurants website.  If offered by the Restaurant you can also arrange for the food to be delivered. Even though you will be ordering directly from your chosen Restaurant we will be facilitating your order and payment acting as the intermediary between you and the Restaurant.</p>

            <h2>Placing an order for food</h2>
            <p>To place an order for food from any Restaurant please select the items you wish to purchase. These will be added to and shown on your basket on the menu page.   You can add items to or remove items from your order ticket at anytime up until the time that you purchase and pay for your food, by clicking on the "Pay" button.</p>

            <p>When you have completed your order you must choose a collection time or confirm delivery location and then click the "Checkout" button on the order page.
                Once on the checkout page you will be prompted to complete certain information, including your payment information.  Once you are happy with your order you must submit the order to the Restaurant by clicking the "Pay" button on the checkout page.</p>

            <p>By clicking on the "Pay" button you acknowledge that you are entering into an obligation to pay for the food for which you have placed an order,.
                The Restaurant will contact you directly if for some reason it is unable to fulfil your order.  If you do not hear from the Restaurant within 20 minutes of placing the order that it is unable to complete your order then a contract is formed between you and the Restaurant (Contract).</p>

            <h2>Our relationship with you</h2>
            <p>When ordering food from your selected Restaurant using our Service you are communicating and eventually entering into a contract with the selected Restaurant rather than us.  We facilitate your ability to order and purchase food from your selected Restaurant.</p>

            <p>We have a limited authority from the Restaurants to act as their agent for the sole purpose of receiving your order, concluding a sale for food by confirming, on behalf of the Restaurant acceptance of an order and receiving payment from you, in each case on behalf of the relevant Restaurant.</p>

            <p>Where an order is concluded so that a Contract is formed, we will also issue you with a confirmatory email receipt on behalf of the relevant Restaurant. You understand and acknowledge that any concluded Contract is between you and the relevant Restaurant and is not between us. We will not be responsible to you for the Contract nor the food ordered nor its delivery and will not be liable to you in respect of any such Contracts.</p>

            <h2>Your Relationship with your selected Restaurant</h2>
            <p>We are providing this service on behalf of the your selected Restaurant and you agree to comply with their terms and conditions.</p>

            <h2>Delivery</h2>
            <p>Where you have chosen to have your food delivered, the Restaurant is responsible for arranging that delivery within the relevant time period.  Place ensure that you provide full information about the delivery address together with any special instructions for delivery at the time you place the order to allow the Restaurant to make a delivery successfully.</p>

            <p>You acknowledge that we have no involvement in the delivery process and therefore that we have no liability to you for any failure by the Restaurant to deliver your ordered food or their failure to deliver the food within any delivery times to which they have agreed.</p>

            <p>You may be asked to provide identification when your order is delivered to prove that you are the person who purchased it.</p>

            <h2>Collection</h2>
            <p>Where you are collecting the food you have ordered instructions for collecting your order will be made available on the confirmation and on your emailed receipt.
                You may be asked to provide identification when you collect your order to prove that you are the person who purchased it.</p>

            <h2>Prices and Payment</h2>
            <p>The price for the food that may be purchased from a Restaurant shall be as quoted on the Service from time to time.  The Restaurant set these prices and is free to amend these prices at their entire discretion.  All prices shown include any applicable VAT, but exclude delivery costs (if applicable) which will be added to your total before you complete your order.</p>

            <p>Payments for all orders must be made by credit or debit card on the checkout page. Payment by most major credit cards is accepted.
                You should be aware that online payment transactions are subject to validation checks by your card issuer and we are not responsible if your card issuer declines to authorise payment for any reason.  Please note, it is possible that your card issuer may charge you an online handling fee or processing fee.  We are not responsible for this.</p>

            <h2>Service and Food Quality provided by a Restaurant </h2>
            <p>We are not responsible for the quality of service or food that you receive from or any delays that you may experience with any Restaurant. If you have any complaints or would like to request a refund, you must contact the Restaurant directly.</p>

            <h2>Food Ingredients</h2>
            <p>It is the responsibility of the relevant Restaurant to inform you of the ingredients that may be contained in the food which you are ordering.  We are not responsible for or able to provide this information for you and do not confirm that the details of any ingredients made available to you through the Service are accurate or correct.  You must check the details of the ingredients provided by the Restaurant and if you have any questions about any such ingredients you must contact the Restaurant directly.  If you have an allergy to any specific ingredients you must discuss this directly with the relevant Restaurant prior to placing an order for food through our Service.</p>

            <p>We shall have no liability to you for inaccuracies in the ingredient information provided by a Restaurant or failure to provide ingredient information or inclusion (accidental or otherwise) of any ingredient which was stated not to be included in the food you have ordered.  Any liability which arises as a result of any failure with respect to disclosing ingredient information or inclusion of any ingredients shall be for the account of the Restaurant and you hereby expressly agree and confirm that we shall no liability in this regard. </p>

            <h2>Using the orderswift Service</h2>
            <p>We do not create accounts for users of the Service but you can consent to us remembering your personal details that you input when placing an order, this will allow you to place orders with other Restaurants using the Service more quickly.  You can also choose not to be remembered. </p>

            <p>You must ensure that any personal details you provide are accurate.  If you choose to be remembered, you must treat your personal information as confidential and must not reveal it to anyone else.  You are responsible for all activities that occur when you are logged on and must notify us immediately of any unauthorised use or other security breach of which you become aware.  We reserve the right to disable any account, at any time, if in our opinion you have failed to comply with any of the provisions of these terms and conditions or if any details you provide for the purposes of registering as a user prove to be false.</p>

            <p>We cannot guarantee the continuous, uninterrupted or error-free operability of the Service. There may be times when certain features, parts or content of the Service, or the entire Service, become unavailable (whether on a scheduled or unscheduled basis) or are modified, suspended or withdrawn by us, in our sole discretion, without notice to you.  You agree that we will not be liable to you or to any third party for any unavailability, modification, suspension or withdrawal of the Service, or any features, parts or content of the Service.</p>

            <h2>What you are allowed to do</h2>
            <p>You may only use the Service for non-commercial use and only in accordance with these terms and conditions.  You may retrieve and display content from the Service on a computer screen, print and copy individual pages and, subject to the next section, store such pages in electronic form.</p>

            <h2>What you are not allowed to do</h2>
            <p>Except to the extent expressly set out in these terms and conditions, you are not allowed to:</p>
            <ul>
                <li><p>store pages of the Service on a server or other storage device connected to a network or create an electronic database by systematically downloading and storing all of the pages of the Service;</p></li>
                <li><p>remove or change any content of the Service or attempt to circumvent security or interfere with the proper working of the Service or the servers on which it is hosted; or</p></li>
                <li><p>create links to the Service from any other website, without our prior written consent, although you may link from a website that is operated by you provided the link is not misleading or deceptive and fairly indicates its destination, you do not imply that we endorse you, your website, or any products or services you offer, you link to (and do not frame or replicate) the home page of the Service, and the linked website does not contain any content that is unlawful, threatening, abusive, defamatory, pornographic, obscene, vulgar, indecent, offensive or which infringes on the intellectual property rights or other rights of any third party.</p></li>
            </ul>

            <p>You must only use the Service and anything available from the Service for lawful purposes (complying with all applicable laws and regulations), in a responsible manner, and not in a way that might damage our name or reputation or that of any of our affiliates.</p>

            <p>All rights granted to you under these terms and conditions will terminate immediately in the event that you are in breach of any of them.</p>

            <h2>Intellectual property rights</h2>
            <p>All intellectual property rights in any content of the Service (including text, graphics, software, photographs and other images, videos, sound, trade marks and logos) are owned by us or our licensors.  Except as expressly set out here, nothing in these terms and conditions gives you any rights in respect of any intellectual property owned by us or our licensors and you acknowledge that you do not acquire any ownership rights by downloading content from the Service.  In the event you print off, copy or store pages from the Service (only as permitted by these terms and conditions), you must ensure that any copyright, trade mark or other intellectual property right notices contained in the original content are reproduced.</p>

            <h2>Content</h2>
            <p>We may change the format and content of the Service from time to time.  You agree that your use of the Service is on an 'as is' and 'as available' basis and at your sole risk.</p>

            <p>We make or give no representation or warranty as to the accuracy, completeness, currency, correctness, reliability, integrity, quality, fitness for purpose or originality of any content of the Service and, to the fullest extent permitted by law, all implied warranties, conditions or other terms of any kind are hereby excluded and we accept no liability for any loss or damage of any kind incurred as a result of you or anyone else using the Service or relying on any of its content.</p>

            <p>We cannot and do not guarantee that any content of the Service will be free from viruses and/or other code that may have contaminating or destructive elements.  It is your responsibility to implement appropriate IT security safeguards (including anti-virus and other security checks) to satisfy your particular requirements as to the safety and reliability of content.</p>

            <h2>Your personal information</h2>
            <p>Use of your personal information submitted to or via the Service is governed by our Privacy and Cookies Policy.</p>

            <h2>General</h2>
            <p>Nothing in these terms and conditions shall limit or exclude our liability to you for death or personal injury caused by our negligence or for fraudulent misrepresentation or any other liability that may not, under English law, be limited or excluded.  Subject to this, in no event shall we be liable to you for any business losses and any liability we do have for losses you suffer is strictly limited to losses that were reasonably foreseeable.</p>

            <p>Subject to this, if you are a consumer and not a business user, in no event shall we be liable to you for any business losses, and if you are a business user, in no event shall we be liable to you for any indirect or consequential losses, or for any loss of profit, revenue, contracts, data, goodwill or other similar losses, and any liability we do have for losses you suffer is strictly limited to losses that were reasonably foreseeable.</p>

            <p>These terms and conditions shall be governed by English law, and you agree that any dispute between you and us regarding them or the Service will only be dealt with by the English courts, provided that, if you are a consumer and not a business user and live in a part of the United Kingdom other than England, the applicable law of that part of the United Kingdom will govern and any dispute will only be dealt with by the courts there.  Nothing shall prevent us from bringing proceedings to protect our intellectual property rights before any competent court.</p>
            </>
        </TinyModal>
    );
};


export default TermsModal;
