import { useContext } from 'react';
import { SiteContext } from './site-context';

const useSiteContext = () => {
    const context = useContext(SiteContext);

    if (context === undefined) {
        throw new Error('useSiteContext must be within a SiteProvider');
    }

    return context;
};

export default useSiteContext;
