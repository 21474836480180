import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { default as config } from '../../config/channels';
import { operatorName } from '../../config/operator';
import useOperatorContext from '../../contexts/operator/useOperatorContext';
import useAppContext from '../../contexts/app/useAppContext';
import PageSpinnerThree from '../general/PageSpinnerThree';
import CheckoutPage from './Checkout/CheckoutPage';
import ConfirmationPage from './Confirmation/ConfirmationPage';
import MenuPage from './Menu/MenuPage';
import SiteSelectPage from './SiteSelect/SiteSelectPage';
import ChannelSelectPage from "./ChannelSelect/ChannelSelectPage";
import TableSelectPage from "./TableSelect/TableSelectPage";

const SiteRoutes = () => {
    const operatorContext = useOperatorContext();
    const appContext = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if ( operatorContext ) {
            operatorContext.fetchOperator(operatorName);
        } else {
            console.warn('Operator context not fired.');
        }

        if (appContext) {
            appContext.setInitialAssetManifest();
        } else {
            console.warn('App context not fired.');
        }
    }, []);

    if (!operatorContext || !operatorContext.operator) {
        if (!operatorContext.loading) {
            navigate('/500');
        }

        return <PageSpinnerThree show={true} />;
    }

    const renderChannelRoutes = () => {
        let channels = [config.collection, config.delivery, config.dineIn];
        return channels.map((channel) => {
            return (
                <React.Fragment key={channel.name}>
                    <Route path={`${channel.handle}/*`} element={
                        <SiteSelectPage channel={channel.name} />
                    } />
                    <Route path={`${channel.handle}/:restaurant_id`} element={
                        <MenuPage channel={channel.name} />
                    } />
                    <Route path={`${channel.handle}/:restaurant_id/checkout`} element={
                        <CheckoutPage />
                    } />
                </React.Fragment>
            );
        });
    };

    return (
        <Routes>
            <Route path="/" element={<ChannelSelectPage />} />

            {renderChannelRoutes()}

            <Route path={`/at-table/:restaurant_id/table`} element={<TableSelectPage />} />
            <Route path={`/at-table`} element={<ChannelSelectPage channel={config.dine_in.name} />} />
            <Route path={`/delivery`} element={<ChannelSelectPage channel={config.delivery.name} />} />
            <Route path={`/collection`} element={<ChannelSelectPage channel={config.collection.name} />} />

            <Route path="/menu/:restaurant_id" element={<MenuPage channel={config.collection.name} />} />
            <Route path="/:restaurant_id" element={<MenuPage channel={config.collection.name} />} />


            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path={`/confirmation/:ref`} element={<ConfirmationPage />} />



            {/* <Route element={ClosedPage} path="/closed" /> */}
            {/* <Route element={ConfirmationPage} path="/confirmation/:order_reference" /> */}
            {/* <Route children={DirectDelivery} path="/delivery" /> */}
        </Routes>
    );
};

export default SiteRoutes;
