import api, { handleError, handleResponse } from '../';
import { Order } from '../../../contexts/basket/basket-context';
import { TChannel } from '../../../contexts/operator/operator-context';
import { OrderData } from '../../../contexts/order/order-context';
import mixpanel from "mixpanel-browser";
import basketItem from "../../../components/pages/Menu/components/basket/BasketItem";
// import { TChannel } from '../../../contexts/operator/operator-context';

export const postValidateOrder = async (order: Order) => {

    let params: any = {
        ...order,
        distinct_id: mixpanel.get_distinct_id()
    }

    return api
        .post('order/validate', params)
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const postValidateTimeslot = async ( basketUid: string|undefined, time: string|undefined, asap: boolean, flow: string ) => {
    return api
        .post('timeslot/validate', { basket_uid: basketUid, asap: asap, time: time, flow: flow })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const postOrder = async (orderData: OrderData) => {

    let params: any = {
        ...orderData,
        distinct_id: mixpanel.get_distinct_id()
    }

    return api
        .post('order', params)
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const getOrder = async (ref: string|undefined) => {
    return api
        .get('order?ref=' + ref)
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const getTimeslots = async (operatorHandle: string, siteHandle: string, channel: TChannel, flow: string) => {
    return api
        .get('timeslots', { params: { handle: operatorHandle, site_handle: siteHandle, channel, flow } })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const postPromoCode = async (
    basket_id: string,
    code: string,
    payment_intent: string,
    flow: string,
    email: string | null,
) => {

    let params: any = {
        basket_id,
        code,
        email,
        flow,
        distinct_id: mixpanel.get_distinct_id()
    }

    return api
        .post('code/validate', params)
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const postCustomer = async (orderRef: string, flow: string) => {
    return api
        .post('sign-up', {
            'order_ref': orderRef,
            'flow': flow
        })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};


// Zillionaire
export const postMember = async (basket: string, email: string, member: string, flow: string) => {
    return api
        .post('member', {
            'basket': basket,
            'email': email,
            'member': member,
            'flow': flow
        })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};





export const getDeliveryFee = async (basket: Array<any>, site: string, destination: string, flow: string, time: string, asap: boolean) => {
    return api
        .post('basket/delivery-fee', {
            'basket': basket,
            'site': site,
            'destination': destination,
            'flow': flow,
            'time': time,
            'asap': asap,

        })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};
