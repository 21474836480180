import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { CTA } from "../../general/Button";
import config from "../../../config/channels";
import useSiteContext from "../../../contexts/site/useSiteContext";
import {useNavigate} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import TinyModal from "../../general/TinyModal";

type LeavingModalProps = {
    show: boolean;
    onHide: () => void;
};

const ChangeAddressModal = ({
                          show,
                          onHide,
                      }: LeavingModalProps) => {

    const navigate = useNavigate();
    const siteContext = useSiteContext();
    const isDineIn = siteContext.channel === config.dineIn.name;


    useEffect(() => {
        document.getElementById('leaving-modal')?.classList.add('anim-enter');

        if ( show ) {
            mixpanel.track('show leaving modal');
        }

        return () => {
            document.getElementById('leaving-modal')?.classList.remove('anim-enter');
        };
    }, [show]);

    const renderFooter = () => {
        return (
            <>
                <CTA secondary buddies
                     onClick={() => navigate(`/${isDineIn ? config.dineIn.handle : siteContext.channel}`)}
                >
                    <span>Leave</span>
                </CTA>
                <CTA buddies
                     onClick={onHide}>
                    <span>Cancel</span>
                </CTA>
            </>
        )
    }

    return (
        <TinyModal
            show={show}
            onHide={onHide}
            modalID={'leaving-modal'}
            footer={renderFooter()}
        >
            <>
                <h4 className='modal__title'>Change address?</h4>
                <p>Changing your delivery address will clear your basket. Are you sure you want to order to a different address?</p>
            </>
        </TinyModal>
    );
};


export default ChangeAddressModal;
