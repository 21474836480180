import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import 'toasted-notes/src/styles.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import './styles/App.scss';

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import mixpanel from "mixpanel-browser";

smoothscroll.polyfill();

Bugsnag.start({
    apiKey: '700638d3e72c5aab32a85af93cdb1112',
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_ENV
})

mixpanel.init(process.env.REACT_APP_MIXPANEL ?? '', {
    debug: process.env.REACT_APP_ENV === 'local'
});

export const iLog = function(params: string, extra: any) {
    if ( process.env.REACT_APP_ENV === 'local' ) { console.log(params, extra) }
}

const ErrorBoundary = Bugsnag
    .getPlugin(`react`)
    ?.createErrorBoundary(React);

if ( ErrorBoundary !== undefined ) {

    ReactDOM.render(
        <ErrorBoundary>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </ErrorBoundary>,
        document.getElementById('root'),
    );

} else {

    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    );

}




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
