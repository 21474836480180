import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/localstorage';
import { CustomerContext, CustomerContextData, CustomerCookie, CustomerFormObject } from './customer-context';

const CustomerContextProvider = ({ children }: { children: JSX.Element }) => {
    const [customerFormData, setCustomerFormData] = useState<CustomerFormObject>({
        cardholderName: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        opt_in_marketing: false,
        checkout_instructions: '',
        timeslot: '',
        // address: '',
        // city: '',
        // postcode: '',
    });

    const [customer, setCustomer] = useState<CustomerCookie | null>(null);
    const [storedCustomer, setStoredCustomer] = useLocalStorage('user', null);

    useEffect(() => {
        if (storedCustomer?.address1) {
            setCustomer(storedCustomer);

            setCustomerFormData({
                cardholderName: storedCustomer.address.cardholderName,
                firstName: storedCustomer.address.firstname,
                lastName: storedCustomer.address.lastname,
                email: storedCustomer.address.email,
                tel: storedCustomer.address.tel,
                opt_in_marketing: storedCustomer.address.opt_in_marketing,
                checkout_instructions: '',
                timeslot: storedCustomer.address.timeslot,
                address1: storedCustomer.address.address1,
                address2: storedCustomer.address.address2,
                postcode: storedCustomer.address.postcode,
                city: storedCustomer.address.city,
            });

        }
    }, [storedCustomer]);

    const updateStoredCustomer = (form: CustomerFormObject) => {
        //if valid form data

        let newCustomer = {
            ...storedCustomer,
            address: {
                ...form,
                firstname: form.firstName,
                lastname: form.lastName,
                cardholderName: form.cardholderName,
            },
            remember_me: true,
        };

        setCustomer(newCustomer);

        setStoredCustomer(newCustomer);
    };

    // useEffect(() => {
    //     if (firstName && lastName && phone && email) {
    //         setValid(true);
    //     } else {
    //         setValid(false);
    //     }
    // }, [firstName, lastName, phone, email]);

    // useEffect(() => {

    //     setStoredCustomer({
    //         ...storedCustomer,
    //         address: {
    //             firstname: firstName,
    //             lastname: lastName,
    //             email: email,
    //             tel: phone,
    //             address: '',
    //             city: '',
    //             postcode: '',
    //         },
    //     });

    // }, [firstName, lastName, email, phone]);

    let CustomerContextValue: CustomerContextData = {
        customer,
        setCustomer,
        customerFormData,
        updateStoredCustomer,
    };
    return <CustomerContext.Provider value={CustomerContextValue}>{children}</CustomerContext.Provider>;
};

export default CustomerContextProvider;
