import { rgba } from 'polished';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../../../contexts/basket/useBasketContext';
import { Item } from '../../../../../contexts/site/site-context';
import { money } from '../../../../../services/format';
import QuantityButton from '../items/modal/components/QuantityButton';
import {iLog} from "../../../../../index";
import mixpanel from "mixpanel-browser";
import useOperatorContext from "../../../../../contexts/operator/useOperatorContext";

type OrderItemProps = {
    item: Item;
    index: number;
    showItemModal: (item: Item, edit: boolean) => void;
};

const OrderItem = ({ item, index, showItemModal }: OrderItemProps) => {
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();

    const [quantity, setQuantity] = useState(item?.quantity ?? 1);


    //const [editModalVisible, setEditModalVisible] = useState(false);
    //const [originalItem, setOriginalItem] = useState<Item | null>(null);

    /*useEffect(() => {
        let cloned= JSON.parse(JSON.stringify(item)) as Item;
        console.log('cloning', cloned);
        setOriginalItem(cloned);
    }, []);*/


    /*const getMaxLimit = (group: ModifierGroup) => {
        let maxLimitReached = false;
        let checked = 0;
        group.modifiers.forEach((mod) => {
            if (mod.selected === true) {
                checked = checked + (mod.quantity ?? 1);
                if (checked >= group.max) {
                    maxLimitReached = true;
                }
            }
        });

        return maxLimitReached;
    };

    const onCancel = () => {
        // reset item back to original state
        setQuantity(originalItem?.quantity ?? 1);
        const itm: Item = { ...originalItem } as Item;
        itm.modifiersTotal = calculateModifiersPrice(itm);
        const index = basketContext?.basketItems?.findIndex((i) => i.uid === itm.uid);
        basketContext.addItem(itm, originalItem?.quantity ?? 0, true, index);
        setEditModalVisible(false);
    };

    const renderModifierGroups = (groups: ModifierGroup[]) => {
        if (groups.length <= 0) {
            return null;
        }

        return (
            <>
                {groups.map((group, index) => (
                    <ModifierGroupWrapper className="mod-group" key={`group-${group.uid}-${index}`}>
                        <p className="mod-group__title">
                            {group.name}
                            {group.min > 0 ? (
                                <span className="required">
                                    {t`Required`} {group.min > 1 ? `(MIN ${group.min})` : ''}
                                </span>
                            ) : null}
                        </p>
                        <p className="mod-group__subtitle">{group.subtitle}</p>
                        {renderModifierItems(group.modifiers, group.max > 1, getMaxLimit(group))}
                    </ModifierGroupWrapper>
                ))}
            </>
        );
    };

    const renderModifierItems = (modifierItems: Modifier[], multiChoice: boolean, maxReached: boolean) => {
        return modifierItems.map((modifierItem, index) => {
            return (
                <div key={`mItem-${modifierItem.uid}-${index}`}>
                    {/!* {modifierItem.name} *!/}
                    <ModifierCheckbox
                        modifier={modifierItem}
                        type={multiChoice ? 'checkbox' : 'radio'}
                        selected={modifierItem.selected ?? false}
                        maxReached={maxReached}
                        updateModifier={updateModifier}
                    />
                </div>
            );
        });
    };

    const updateModifier = (updatedModifier: Modifier, multi: boolean) => {
        // Map updated mod into selectedItem
        let newItem: Item = { ...item };
        let found = false;

        newItem.modifier_groups = newItem.modifier_groups.map((group) => {
            if (group.uid !== updatedModifier.group_uid) {
                return group;
            }

            group.modifiers = group.modifiers.map((mod) => {
                if (mod.uid === updatedModifier.uid) {
                    if (!updatedModifier.quantity && updatedModifier.selected) {
                        updatedModifier.quantity = 1;
                    }
                    found = true;
                    return updatedModifier;
                }

                // Unselect others if radio
                if (mod.selected && !multi) {
                    mod.quantity = 0;
                    mod.selected = false;
                }

                return mod;
            });

            return group;
        });

        // calculate modifiers total
        newItem.modifiersTotal = calculateModifiersPrice(newItem);
        // console.log('update modifier, match found?', found, newItem);
        const index = basketContext.basketItems.findIndex((i) => i.uid === newItem.uid);
        basketContext.addItem(newItem, quantity, index >= 0, index);
    };*/

    /*const renderTitleBadges = () => {
        let badges = [];
        if (item.is_new) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-new`}>
                    <FontAwesomeIcon className="badge__icon" size="sm" icon={faStar} />
                    New
                </Badge>,
            );
        }

        if (item.is_special) {
            badges.push(
                <Badge key={`tfbg-${item.uid}-special`}>
                    <FontAwesomeIcon className="badge__icon" size="sm" icon={faStar} />
                    Special
                </Badge>,
            );
        }

        if (badges.length > 0) {
            return badges.map((badge) => badge);
        }

        return null;
    };*/

    useEffect( () => {

        if ( quantity < 1 ) {
            iLog('OrderItem: quantity now 0. Remove item.', item);
            mixpanel.track('Item removed (qty 0)');
            basketContext.removeItem(item);
            return;
        }

        if ( item.quantity !== quantity ) {
            iLog('OrderItem: quantity has changed', [item.name, quantity, item.quantity !== quantity]);
            basketContext.addItem(item, quantity, true);
        }

    }, [ quantity ])

    useEffect( () => {
        if ( item.quantity ) {
            setQuantity(item.quantity);
        }
    }, [ item ]);

    const renderItemTimeRestrictionError = () => {
        if ( !operatorContext.operator?.has_timed_categories ) {
            return null;
        }


        return (
            <div className={'b-item__error'}>
                <i className="feather feather-alert-circle feather-20 color-filter-invalid" />
                <span>Item unavailable at your collection time</span>
            </div>
        );
    };

    return (
        <Layout debug_error_style={index % 2 === 0} onClick={() => {
            mixpanel.track('Edit item clicked');
            showItemModal(item, true)
        }}>

            <Details>
                <div className={"line-item" + (index % 2 === 0 ? ' line-item--unavailable' : '')}>
                    <div className="b-item-name">
                        {item.name}
                    </div>
                    <div className={'b-item-values'}>
                        <QuantityButton
                            quantity={quantity}
                            setQuantity={setQuantity}
                            type={'small'}
                        />
                        <div className="b-item-price">
                            {money(item.price * (item.quantity ?? 1) + (item.modifiersTotal ?? 0) * (item.quantity ?? 1))}
                        </div>
                    </div>
                </div>
                {item.modifiers?.length ? (
                    <div className="b-item-mod">
                        {item.modifiers?.map((modifier, index) => {

                            // item's group
                            let group = item.modifier_groups.find((mg) => mg.uid === modifier.group_uid);

                            if ( group && !group?.multi_choice && modifier.quantity === -1 ) {
                                return '';
                            }

                            return <span key={`basket-item-mod-${modifier.uid}`}>
                                { modifier.quantity === -1 ? 'No '+ modifier.name : modifier.name }
                                { modifier.quantity > 1 ? ' ('+ modifier.quantity +')' : '' }
                                { item.modifiers && index < item.modifiers?.length - 1 ? ', ' : ''}
                            </span>;
                        })}
                    </div>
                ) : null}

                {renderItemTimeRestrictionError()}

                {/*<div className="b-item-controls">*/}
                {/*     /!*<span className="b-item-remove" onClick={() => basketContext.removeItem(item)}>{t`Remove`}</span>*!/*/}

                {/*    /!* To do: add functionality *!/*/}
                {/*    <span className="b-item-edit" onClick={() => {*/}
                {/*        mixpanel.track('Edit item clicked');*/}
                {/*        showItemModal(item, true)*/}
                {/*    }}>{t`Edit item`}</span>*/}
                {/*</div>*/}
            </Details>

        </Layout>
    );
};

const ModalWrapper = styled.div`
    .modal-content {
        padding: 0 !important;
    }

    .closeBtn {
        cursor: pointer;
        opacity: 1;
    }

    button.close {
        display: none;
    }
`;

const Details = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    .line-item {
        display: flex;
        gap: 12px;
        align-items: flex-start;
    }

    .b-item__error {
        margin-top: 8px;
        color: var(--invalid);
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .b-item-name {
        flex-grow: 1;
    }
    
    .b-item-price {
        min-width: 74px;
        text-align: right;
    }

    .b-item-name,
    .b-item-price {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: var(--text-black);
    }

    .b-item-values {
        flex-shrink: 0;
        gap: 12px;
        display: flex;
        align-items: center;
    }

    .b-item-remove {
        color: var(--invalid);
        cursor: pointer;
        user-select: none;
    }


    .b-item-mod {
        font-size: 14px;
        line-height: 19px;
        color: var(--text-grey);
        margin-top: 4px;
        font-weight: 400;
    }

    .b-item-controls {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin-top: 16px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .b-item-edit {
            color: var(--brand);
            cursor: pointer;
            user-select: none;
            font-weight: 500;
            display: inline-block;
            position: relative;
            ::after {
                position: absolute;
                content: '';
                left: 0;
                right: 0;
                bottom: -2px;
                border-bottom: 1px solid transparent;
                transition: border-color 0.15s ease-in-out;
            }
            :hover {
                ::after {
                    border-color: var(--brand);
                }
            }
        }

    }
`;


const Layout = styled.div<{debug_error_style: boolean}>`
    width: 100%;
    // border-bottom: 1px solid var(--border-grey);
    cursor: pointer;
    padding: 8px 20px;
    &:first-child {
        padding-top: 20px;
    }
    &:hover {
        background-color: var(--brand-10);
    }

    ${(props) => props.debug_error_style ? `
        // border-color: var(--invalid);
        // box-shadow: inset 0 0 0 1px var(--invalid);
    ` : ''}
`;

/*function calculateModifiersPrice(newItem: Item) {
    let total = 0;
    newItem.modifier_groups.forEach((group) => {
        let groupModsSelected = 0;

        group.modifiers.forEach((mod) => {
            if (mod.selected || mod.quantity) {
                // selectedMods.push(mod);
                groupModsSelected += mod.quantity ?? 1;
                total += mod.price * (mod.quantity ?? 1);
            }
        });
    });

    return total;
}*/

export default OrderItem;
