import axios, { AxiosResponse } from 'axios';
import { t } from 'ttag';
import { toastError } from '../../hooks/toastError';
import { iLog } from "../../index";
import toaster from "toasted-notes";
import api from "./index";

const API_URL = process.env.REACT_APP_API_URL;

const alert = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});

alert.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

export const trackAlert = (name: string, operator: string, message: string) => {
    return alert
        .get('alert', { params: {
                name,
                operator,
                message
            }})
        .then((response) => {})
        .catch((error) => {});
};

export default alert;
