import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { rgba } from 'polished';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import styled, { ThemeContext } from 'styled-components';
import { t } from 'ttag';
import useSiteContext from '../../../../../contexts/site/useSiteContext';
import Button from '../../../../general/Button';
import PageSpinnerThree from "../../../../general/PageSpinnerThree";

interface SiteMapProps {
    children?: ReactNode;
}

const options = {
    scrollwheel: false,
    zoom: 15,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
};

const SiteMap = ({ children }: SiteMapProps) => {
    const siteContext = useSiteContext();
    // const [center, setCenter] = useState<>(null)
    const [icon, setIcon] = useState<google.maps.Symbol>();
    const [loaded, setLoaded] = useState(false);
    const [center, setCenter] = useState({ lat: 51.612737, lng: -0.541332 });
    const themeContext = useContext(ThemeContext);

    const containerStyle = {
        width: '100%',
        height: children ? '140px' : '200px',
    };

    const loadMap = () => {
        setIcon({
            path:
                'M13.3338 30.2413C9.52707 29.8109 6.02929 27.9405 3.55762 25.0135C1.08595 22.0866 -0.172316 18.325 0.0408352 14.5C0.253987 10.675 1.9224 7.07644 4.70397 4.44225C7.48554 1.80806 11.1695 0.337823 15.0004 0.333012C18.8361 0.330218 22.5271 1.7969 25.3147 4.43157C28.1023 7.06624 29.7747 10.6687 29.9881 14.4984C30.2015 18.3281 28.9397 22.0941 26.4621 25.0222C23.9845 27.9503 20.4793 29.818 16.6671 30.2413V36.9997H13.3338V30.2413Z',
            fillColor: themeContext.color_primary,
            fillOpacity: 1,
            strokeWeight: 0,
            anchor: new google.maps.Point(16, 37),
            scale: 1,
        });
        setLoaded(true);
    };

    useEffect(() => {
        setCenter({ lat: siteContext.site?.lat ?? 51.612737, lng: siteContext.site?.lng ?? -0.541332 });
    }, [siteContext]);

    const RenderMap = () => {
        return (
            <StyledMap>
                <LoadScript
                    googleMapsApiKey="AIzaSyCaDHOPEJyMCctOQ5BhW1uUXSuIspSVsfc"
                    loadingElement={
                        <MapLoading>
                            <PageSpinnerThree show={true} />;
                        </MapLoading>
                    }
                >
                    <GoogleMap mapContainerStyle={containerStyle} center={center} options={options} onLoad={loadMap}>
                        {loaded ? <Marker position={center} icon={icon} animation={2} /> : null}
                    </GoogleMap>
                </LoadScript>

                <a
                    target="_blank"
                    href={
                        'http://maps.google.com/?q=' +
                        siteContext.site?.address_1 +
                        ',' +
                        siteContext.site?.address_postcode
                    }
                >
                    <MapButton>{t`Open in Google Maps`}</MapButton>
                </a>
            </StyledMap>
        );
    };

    const renderDetails = () => {
        if (children) {
            return <Details>{children}</Details>;
        }
        return null;
    };

    return (
        <Wrapper>
            {renderDetails()}

            {siteContext && themeContext ? RenderMap() : null}
        </Wrapper>
    );
};

const MapLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--bg-grey);
`;
const Wrapper = styled.div`
    background-color: ${(props) => props.theme.color_shade_5};
    border-radius: 10px;
    overflow: hidden;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
`;

const Details = styled.div`
    background-color: ${(props) => props.theme.color_shade_0};
    border-radius: 10px 10px 0px 0px;
    border: 1px solid ${(props) => props.theme.color_border};
    padding: 12px 1rem;
`;

const StyledMap = styled.div`
    position: relative;
    flex-grow: 1;
`;

const MapButton = styled(Button)`
    padding: 4px 12px;
    margin: 8px 6px;
    font-size: 13px;
    font-weight: 500;
    background-color: ${(props) => props.theme.color_shade_0};
    color: ${(props) => props.theme.color_primary};
    position: absolute;
    right: 0;
    bottom: 0;
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 4px ${(props) => rgba(props.theme.color_secondary, 0.1)};
    }
`;

export default SiteMap;
