import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

type SmallModalProps = {
    onHide: () => void;
    show: boolean;
    header?: JSX.Element;
    footer?: JSX.Element;
    html?: string;
    children?: JSX.Element;
    expandMobile?: boolean;
    showClose?: boolean;
    className?: string;
    scrollHeader?: boolean;
};

const SmallModal = ({
    show,
    header,
    footer,
    children,
    html,
    onHide,
    expandMobile = true,
    showClose,
    className,
    scrollHeader = false,
}: SmallModalProps) => {
    return (
        <StyledModal
            expand={expandMobile ? 1 : 0}
            show={show}
            onHide={onHide}
            size="lg"
            scrollHeader={scrollHeader}
            backdrop="static"
            className={className}
            centered
        >
            {header ? <Modal.Header closeButton={showClose}>
                <StyledContainer>
                    {header}
                </StyledContainer>
            </Modal.Header> : null}
            <Modal.Body>{html ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : children}</Modal.Body>
            {footer ? <Modal.Footer>{footer}</Modal.Footer> : null}
        </StyledModal>
    );
};

const StyledContainer = styled.div`
     max-width: 1176px;
    margin: 0 auto;
    width: 100%;
    @media screen and (min-width: 1441px){
        max-width: 1260px;
    }
`;

const StyledModal = styled(Modal)<{ expand: boolean, scrollHeader: boolean }>`
    padding: 0 !important; // override inline padding-right added from js

    .modal-title {
        font-weight: 400;
    }
    .modal-content {
        border: 0;
    }

    .modal-header,
    .modal-body,
    .modal-header {
        border: 0;
    }

    /* make modal content and header scroll, keep bottom fixed */
    ${(props) => props.scrollHeader ? `
        .modal-body {
            overflow: visible !important;
        }

        .modal-content {
            overflow: scroll !important;
            padding-bottom: 80px !important;
        }

        .modal-footer {
            position: fixed !important;
            bottom: 0 !important;
            width: 100vw !important;
        }
    ` : null}

    ${(props) =>
        props.expand
            ? `
        .modal-header, .modal-body, .modal-header {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .modal-dialog {
            width: 100%;
            max-width: none;
            margin: 0;
            height: 100%;
        }
        .modal-content {
            height: 100%;
            border-radius: 0;
        }
        .modal-body {
            overflow-y: auto;
            padding: 2rem;
        }

        .modal-header {
            padding: 20px 2rem;
            box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
            h5 {
                padding-top: 2px;
            }
        }
        @media(min-width: 768px){
            .modal-content {
                padding: 0x;
            }
            .modal-header, .modal-body, .modal-footer {
                padding: 0;
            }
            .modal-dialog {
                max-width: 400px;
                margin: 16px auto;
                height: auto;
            }
            .modal-body {
                overflow-y: unset;
                padding-top: 0;
            }
            .modal-content {
                height: auto;
                background-color: #FFFFFF;
                box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
            }
            .modal-header {
                box-shadow: none;
                background-color: transparent;
                position: relative;
                z-index: 1;
            }
        }
        `
            : `
        .modal-header, .modal-body, .modal-footer {
            border: 0;
        }
        .modal-dialog {
            max-width: 430px;
            margin: 1.75rem auto;
            padding: 15px;
        }
        .modal-content {
            padding: 0px;
            background-color: #FFFFFF;
            box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
        }
       .modal-body {
            padding: 0;
        }
        .modal-footer {
            display: flex;
            flex-wrap: nowrap;
            padding: 0;
            > * {
                margin: auto;
            }
        }
        .modal-title {
            font-weight: 400;
        }
        .modal-header {
            padding: 0;
            box-shadow: none;
            background-color: transparent;
            position: relative;
            z-index: 1;
        }`}
`;

export default SmallModal;
