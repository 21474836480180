import { createContext } from 'react';

export interface CustomerCookie {
    address: TCustomer;
    remember_me: boolean;
    remembered_address: TCustomer;
}

export interface TCustomer {
    firstname: string;
    lastname: string;
    cardholderName: string;
    address: string;
    city: string;
    postcode: string;
    email: string;
    tel: string;
    timeslot?: string;
}

export type CustomerFormObject = {
    firstName: string;
    lastName: string;
    cardholderName: string;
    email: string;
    tel: string;
    opt_in_marketing: boolean;
    remember_me?: boolean;
    checkout_instructions: string;
    address1?: string;
    address2?: string;
    city?: string;
    postcode?: string;
    timeslot?: string;
};

export type CustomerContextData = {
    customer: CustomerCookie | null;
    setCustomer: (customer: CustomerCookie | null) => void;

    customerFormData: CustomerFormObject;
    // setCustomerFormData: (form: CustomerFormObject) => void;
    updateStoredCustomer: (form: CustomerFormObject) => void;
};

export const CustomerContext = createContext<CustomerContextData>({
    customer: {
        address: {
            cardholderName: '',
            firstname: '',
            lastname: '',
            email: '',
            tel: '',
            address: '',
            city: '',
            postcode: '',
        },
        remember_me: true,
        remembered_address: {
            cardholderName: '',
            firstname: '',
            lastname: '',
            email: '',
            tel: '',
            address: '',
            city: '',
            postcode: '',
        },
    },
    setCustomer: () => {},

    customerFormData: {
        cardholderName: '',
        firstName: '',
        lastName: '',
        email: '',
        tel: '',
        opt_in_marketing: false,
        checkout_instructions: '',
        // address: '',
        // city: '',
        // postcode: '',
    },

    // setCustomerFormData: () => { },
    updateStoredCustomer: () => {},
});
