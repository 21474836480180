import api, { handleError, handleResponse } from '../';
import mixpanel from "mixpanel-browser";

export const getOperator = (handle: string) => {
    return api
        .get('operator', { params: { handle } })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const trackEvent = (name: string, site: string) => {
    return api
        .get('event', { params: {
            name,
            site,
            distinct_id: mixpanel.get_distinct_id() } }
        )
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};
