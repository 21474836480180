import { useEffect, useRef, useState } from 'react';

export function useStateRef<Type>(
    initialValue: Type,
): [Type, React.Dispatch<React.SetStateAction<Type>>, React.MutableRefObject<Type>] {
    const [value, setValue] = useState<Type>(initialValue);

    const ref = useRef<Type>(value);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return [value, setValue, ref];
}
