import React, {useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import {CTA} from "../../general/Button";
import config from "../../../config/channels";
import useSiteContext from "../../../contexts/site/useSiteContext";
import {useNavigate} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import TinyModal from "../../general/TinyModal";

type ChannelClosedWelcomeModalProps = {
    channel: string;
    show: boolean;
    singleSiteAndChannel: boolean;
    onHide: () => void;
};

const ChannelClosedWelcomeModal = ({
    channel,
    show,
    singleSiteAndChannel,
    onHide,
}: ChannelClosedWelcomeModalProps) => {

    const navigate = useNavigate();
    const siteContext = useSiteContext();
    const isDineIn = siteContext.channel === config.dineIn.name;


    useEffect(() => {
        document.getElementById('leaving-modal')?.classList.add('anim-enter');

        if ( show ) {
            mixpanel.track('show channel closed');
        }

        return () => {
            document.getElementById('leaving-modal')?.classList.remove('anim-enter');
        };
    }, [show]);

    function capitalizeFirstLetter(string: String) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const backButtonClick = () => {
        if ( siteContext.channel === config.dineIn.name ) {
            navigate(`/${config.dineIn.handle}`);
        } else if ( siteContext.channel === config.delivery.name ) {
            localStorage.removeItem('postcode');
            navigate(`/`);
        } else {
            navigate(`/collection`);
        }
    }

    function channelTextString() {
        switch ( channel ) {
            case config.dineIn.name: return 'at table ';
            case config.collection.name: return 'for collection ';
            case config.delivery.name: return 'for delivery ';
        }
        return '';
    }

    const channelHeaderString = () => {
        switch ( channel ) {
            case config.dineIn.name: return 'Order at table'
            case config.collection.name: return 'Collection';
            case config.delivery.name: return 'Delivery';
        }
        return '';
    }

    const textString = () => {
        return singleSiteAndChannel
            ? 'This location is not currently accepting orders. Would you like to view the menu?'
            : 'This location is not currently accepting orders. Would you like to view the menu or order ' + channelTextString() + 'from another locations?';
    }

    const renderFooter = () => (
        <>
            { !singleSiteAndChannel && (
                <CTA secondary buddies
                     onClick={() => backButtonClick()}
                >
                    <span>Go back</span>
                </CTA>
            )}
            <CTA buddies
                 onClick={onHide}>
                <span>View menu</span>
            </CTA>
        </>
    )

    return (
        <TinyModal
            show={show}
            onHide={onHide}
            footer={renderFooter()}
            modalID={'leaving-modal'}
        >
           <>
               <h4 className='modal__title'>{ channelHeaderString() } not available</h4>

               <p>{ textString() }</p>
           </>
        </TinyModal>
    );
};

export default ChannelClosedWelcomeModal;
