import { useEffect, useState } from "react";

const useScrollPosition = (axis?: string) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const updatePosition = () => {
        if ( axis === 'X' ) {
            setScrollPosition(window.pageXOffset)
        } else {
            setScrollPosition(window.pageYOffset);
        }
      }
      window.addEventListener("scroll", updatePosition);
      updatePosition();

      return () => window.removeEventListener("scroll", updatePosition);
    }, []);

    return scrollPosition;
};

export default useScrollPosition;