import React from 'react';
import styled, { keyframes } from 'styled-components';
import useOperatorContext from "../../contexts/operator/useOperatorContext";

type SpinnerProps = {
    children?: JSX.Element;
    show: boolean;
    text?: string;
    color?: string;
    icon?: string;
};

// https://jsfiddle.net/zsuLqgp7/

const PageSpinnerThree = ({ children, show = false, text = '', color, icon = ''}: SpinnerProps) => {

    const operatorContext = useOperatorContext();

    return (
        <>
            <Wrapper>
                <Dots show={show} color={color ? color : operatorContext.operator?.theme.v3.ui.other.link}>
                    <span />
                    <span />
                    <span />
                </Dots>
            </Wrapper>
            { children }
            { icon !== '' ? <img src={icon} className="mr-1" alt="icon" /> : '' }
            <span style={ {opacity: (show ? '0' : '1')} }>{text}</span>
        </>
    );
};

const pulse = keyframes`
    50% { opacity: 0; transform: scale(0.7); }
`;

const Wrapper = styled.div`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%; height: 100%;
`;
const Dots = styled.div<{ show: boolean, color: string | undefined }>`

    align-items: center;
    justify-content: space-between;
    width: 30px;
    height: 100%;
    margin: 0 auto;

    ${ (props) => props.show ? 'display:  flex;' : 'display: none;' }

    & span {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${ (props) => props.color };
        animation: ${pulse} 1.5s infinite ease-in-out;
        animation-delay: 0s;
    }

    & span:nth-child(2) { animation-delay: 0.4s; }
    & span:nth-child(3) { animation-delay: 0.8s; }
`;



export default PageSpinnerThree;
