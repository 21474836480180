import { useContext } from 'react';
import { BasketContext } from './basket-context';

const useBasketContext = () => {
    const context = useContext(BasketContext);

    if (context === undefined) {
        throw new Error('useBasketContext must be within a BasketProvider');
    }

    return context;
};

export default useBasketContext;
