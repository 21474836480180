var host = window.location.host,
    chain = host.split('.')[0],
    domain = host.split('.')[1];


// Some customers have custom domains like order.askitalian.co.uk
// therefore we need to get the chain from the second portion
// of the host, not the first.

let roots = ['orderswift'];

if ( process.env.REACT_APP_ENV === 'staging' ) {
    roots = ['v3', 'nodowntime', 'v4'];
}

if ( process.env.REACT_APP_ENV === 'production' && domain === 'v3' ) {
    roots = ['v3'];
}

chain = (roots.includes(domain) ? chain : domain)

console.log('Welcome to ' + chain);

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'local') {
    chain = process.env.REACT_APP_DEFAULT_SUBDOMAIN ?? 'food';
}


export const operatorName = chain;
