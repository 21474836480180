import React, {useState} from 'react';
import styled from 'styled-components';
// @ts-ignore
import DOMPurify from 'dompurify';

interface BannerProps {
    text: string;
    scrolled: boolean;
}

const Banner = ({ text, scrolled }: BannerProps) => {
    const [show, setShow] = useState(true);

    return (
        // onClose={() => setShow(false)}
        <StyledBanner className={(!scrolled && show ? 'show ' : '')} >
            <button onClick={() => setShow(false)}>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} className={'text-ui-font'} />
                <i className={'feather feather-x feather-20'}></i>
            </button>
        </StyledBanner>
    );
};

const StyledBanner = styled.div`
    
    z-index: 999;
    position: fixed;
    bottom:0;
    left: 0;
    right: 0;
    padding: 0 16px 97px 16px;
    display: flex;
    justify-content: center;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transform: translateY(100px);
    pointer-events: none;
    opacity: 0;
    &.show {
        opacity: 1;
        transform: translateY(0);
    }
    > button {
        background-color: var(--ui_banner_bg);
        color: var(--ui_banner_color);
        font-size: 14px;
        line-height: 20px;
        padding: 18px 20px;
        border-radius: 6px;
        box-shadow: 0 4px 16px -2px rgb(0 0 0 / 15%);
        border: 0;
        display: flex;
        align-items: center;
        text-align: left;
        gap: 12px;
        pointer-events: all;
        span {
            margin-bottom: 0;
            font-family: var(--text_ui_font);
            font-weight: var(--text_ui_weight);
            text-transform: var(--text_ui_case);
            font-size: var(--core_text_ui_lg);
            @media screen and (min-width: 576px) {
                font-size: var(--core_text_ui_lg_desktop);
            }
            line-height: 20px;
            
        }
    }
    @media screen and (min-width: 768px) {
        padding: 0 16px 24px 16px;
        > button span {
            font-size: 18px;
        }
    }
  
    a, .close {
        color: var(--ui_banner_color);
    }
    @media screen and (min-width: 1140px) {
        /* top: 64px; */
        // top: 0;
    }
`;

export default Banner;
