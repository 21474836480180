import { useContext } from 'react';
import { AppContext } from './app-context';

const useAppContext = () => {
    const context = useContext(AppContext);

    if (context === undefined) {
        throw new Error('useAppContext must be within an AppProvider');
    }

    return context;
};

export default useAppContext;
