import { useContext } from 'react';
import { OperatorContext } from './operator-context';

const useOperatorContext = () => {
    const context = useContext(OperatorContext);

    if (context === undefined) {
        throw new Error('useOperatorContext must be within a OperatorProvider');
    }

    return context;
};

export default useOperatorContext;
