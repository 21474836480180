import { Formik } from 'formik';
import React from 'react';
import { t } from 'ttag';
import * as Yup from 'yup';
import config from '../../../../config/channels';
import { CustomerFormObject } from '../../../../contexts/customer/customer-context';
import useCustomerContext from '../../../../contexts/customer/useCustomerContext';
import useSiteContext from '../../../../contexts/site/useSiteContext';
import PaymentForm from './PaymentForm';
import {iLog} from "../../../../index";
import {useLocalStorage} from "../../../../hooks/localstorage";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";

export type FormData = {
    firstName?: boolean;
    lastName?: boolean;
    cardholderName?: boolean;
    email?: boolean;
    phone?: boolean;
    address?: boolean;
    table_select?: boolean;
    show_instructions?: boolean;
    opt_in_marketing?: boolean;
};

interface FormBuilderProps {
    form: FormData;
}

// RegEx for phone number validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = {
    firstName: Yup.string()
        // .min(2, "First Name must have at least 2 characters")
        .max(100, t`First name can't be longer than 100 characters`)
        .required(t`Enter a first name`),

    lastName: Yup.string()
        .min(2, t`Last name must have at least 2 characters`)
        .max(100, t`Last name can't be longer than 100 characters`)
        .required(t`Enter a second name`),

    email: Yup.string()
        .email(t`Must be a valid email address`)
        .max(100, t`Email must be less than 100 characters`)
        .required(t`Enter an email`),

    // cardholderName: Yup.string()
    //     .min(3, 'Cardholder name is required (First and last name)')
    //     .max(100, 'Name cant be longer than 100 characters.')
    //     .matches(/^[a-zA-Z0-9]+\s[a-zA-Z0-9]+$/, 'Cardholder name is required (First and last name)')
    //     .required('Cardholder name is required (First and last name)'),

    opt_in_marketing: Yup.boolean(),
    checkout_instructions: Yup.string().max(100),
};

// Schema for yup
const validationSchemaCollection = Yup.object().shape({
    ...validationSchema,

    tel: Yup.string()
        .matches(phoneRegExp, t`Phone number is not valid`)
        .required(t`Enter a phone number`),

    //timeslot: Yup.string().required(t`*Please select a time`),
});

const validationSchemaDineIn = Yup.object().shape({
    ...validationSchema,
});

const validationSchemaDelivery = Yup.object().shape({
    ...validationSchema,

    tel: Yup.string()
        .matches(phoneRegExp, t`Phone number is not valid`)
        .required(t`Enter a phone number`),

    address1: Yup.string().required(t`Enter an address`),
    city: Yup.string().required(t`Enter a city`),
    postcode: Yup.string().required(t`Enter a postcode`),

    //timeslot: Yup.string().required(t`*Please select a time`),
});

const FormBuilder = ({ form }: FormBuilderProps) => {
    const customerContext = useCustomerContext();
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    
    const address = JSON.parse(localStorage.getItem('address') ?? '{}');
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');

    const initialValues: CustomerFormObject = {
        ...customerContext.customerFormData,
        address1: address.address1 ?? '',
        address2: address.address2 ?? '',
        city: address.city ?? '',
        postcode: localStorage.getItem('postcode') ?? customerContext.customerFormData.postcode,
        opt_in_marketing: operatorContext.operator?.theme.v3.default_opt_in ?? false,

        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        tel: user.tel ?? '',
        email: user.email ?? '',
        remember_me: user.remember_me ?? false
    }

    const schema = () => {
        switch (siteContext.channel) {
            case config.delivery.name:
                return validationSchemaDelivery;

            case config.dineIn.name:
                return validationSchemaDineIn;

            default:
                return validationSchemaCollection;
        }
    };

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                isInitialValid={() => schema().isValidSync(initialValues)}
                validationSchema={schema()}
                onSubmit={(values, { setSubmitting, resetForm, validateForm }) => {

                    // When button submits form and form is in the process of submitting, submit button is disabled
                    setSubmitting(true);

                    iLog('form values', values);

                    validateForm().then(() => {
                        // Resets form after submission is complete
                        // customerContext.setCustomerFormData(values);
                        customerContext.updateStoredCustomer(values);
                        // alert(JSON.stringify(values, null, 2));
                        // resetForm();
                    });

                    // Sets setSubmitting to false after form is reset
                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, isValid, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <>
                        <PaymentForm form={form} />
                    </>
                )}
            </Formik>
        </>
    );
};

export default FormBuilder;
