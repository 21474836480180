import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

interface Props {
    selected: boolean;
    onChange(): any;
}

const Radio = (props: Props) => {
    const themeContext = useContext(ThemeContext);
    
    return (
        <StyledRadio className="radio" onClick={props.onChange}>
            {props.selected ? (
                <>
                    <svg height="20" width="20" viewBox="0 0 20 20">
                        <path
                            d="M10 0C4.48714 0 0 4.48714 0 10C0 15.5129 4.48714 20 10 20C15.5129 20 20 15.5129 20 10C20 4.48714 15.5129 0 10 0ZM10 17.1429C6.06143 17.1429 2.85714 13.9386 2.85714 10C2.85714 6.06143 6.06143 2.85714 10 2.85714C13.9386 2.85714 17.1429 6.06143 17.1429 10C17.1429 13.9386 13.9386 17.1429 10 17.1429Z"
                            fill={themeContext.v3.ui.other.link}
                        />
                        <path
                            d="M10 6C7.83067 6 6 7.83067 6 10C6 12.1693 7.83067 14 10 14C12.1693 14 14 12.1693 14 10C14 7.83067 12.1693 6 10 6Z"
                            fill={themeContext.v3.ui.other.link}
                        />
                    </svg>
                </>
            ) : (
                // <svg height="18" width="18" viewBox="0 0 22 22">
                //     <path
                //         d="M9.99999 1.66666C5.40499 1.66666 1.66666 5.40499 1.66666 9.99999C1.66666 14.595 5.40499 18.3333 9.99999 18.3333C14.595 18.3333 18.3333 14.595 18.3333 9.99999C18.3333 5.40499 14.595 1.66666 9.99999 1.66666Z"
                //         fill={themeContext.color_border}
                //     />
                // </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M0 10C0 15.5129 4.48571 20 10 20C15.5129 20 20 15.5129 20 10C20 4.48714 15.5129 0 10 0C4.48571 0 0 4.48714 0 10ZM17.1429 10C17.1429 13.9386 13.9386 17.1429 10 17.1429C6.06143 17.1429 2.85714 13.9386 2.85714 10C2.85714 6.06143 6.06143 2.85714 10 2.85714C13.9386 2.85714 17.1429 6.06143 17.1429 10Z" fill="#E7E7E7"/>
                </svg>
            )}
        </StyledRadio>
    );
}

const StyledRadio = styled.div`
    margin-top: -1px;
    &:first-child {
        margin-top: 0;
    }
`;

export default Radio;
