import styled from "styled-components";
import useSiteContext from "../../../contexts/site/useSiteContext";
import config from '../../../config/channels';
import { useNavigate, useSearchParams } from "react-router-dom";
import { t } from "ttag";
import useBasketContext from "../../../contexts/basket/useBasketContext";
import LeavingModal from "./LeavingModal";
import React, {useState} from "react";
import {iLog} from "../../../index";
import useOperatorContext from "../../../contexts/operator/useOperatorContext";

type MenuFilterRowProps = {
    setPreferencesShow: (arg0: boolean) => void;
};

const MenuFilterRow = ({setPreferencesShow}: MenuFilterRowProps) => {

    const siteContext = useSiteContext();
    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();
    const isDineIn = siteContext.channel === config.dineIn.name;
    const navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const [leavingShow, setLeavingShow] = useState(false);

    const handleLeavingClick = () => {
        iLog('Leaving so soon?', [basketContext.basketItems.length, basketContext.basketItems.length > 0])

        if ( basketContext.basketItems.length > 0 ) {
            setLeavingShow(true);
        } else {
            navigate(`/${isDineIn ? config.dineIn.handle : siteContext.channel}`);
        }
    };

    const styleVariant = operatorContext.operator?.theme?.v3.ux.menu_layout ?? 'classic';

    return (
        <StyledRow>
            {/*{ operatorContext.operator?.theme.v3.ux.filters && (*/}
            {/*    <FilterButton noShrink={true} onClick={() => setPreferencesShow(true)}>*/}
            {/*        <i className="feather feather-sliders feather-20 brand-color-filter" />*/}
            {/*    </FilterButton>*/}
            {/*)}*/}
            <FilterButton
                role="button"
                style={{ fontSize: '14px' }}
                onClick={handleLeavingClick}
                className="location-btn"
                variant={styleVariant}
                >
                <i className="feather feather-map-pin feather-20 color-filter-change-location" />

                {siteContext.channel === 'collection' && (
                    <>
                        <span>{siteContext.site?.name}</span>
                    </>
                )}

                {siteContext.channel === 'delivery' && <>
                    <span>{localStorage.getItem('postcode') ?? '/'}</span>
                </>}

                {siteContext.channel === 'dine_in' && <>
                    <span>{siteContext.site?.name} - {t`Table `} {basketContext.tableNumber}</span>
                </>}
            </FilterButton>
            <LeavingModal
                show={leavingShow}
                onHide={() => { setLeavingShow(false); }}
            />
        </StyledRow>

    )
}


const StyledRow = styled.div`
    display: flex;
    gap: 16px;
    flex-wrap: none;
    margin-top: 4px;
    width: 100%;
    overflow-x: visible;
    padding: 0 20px;
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

const FilterButton = styled.button<{noShrink?: boolean, variant?: string}>`
    padding: 4px 16px;
    gap: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    display: inline-flex;
    color: var(--ui_buttons_change_location_color);
    font-family: var(--text_ui_font);
    text-transform: var(--text_ui_case);
    border: 1px solid var(--brand);
    border-radius: 6px;
    background-color: #FFFFFF;
    
    ${(props) => props.variant === 'portrait' ? 'background-color: transparent;' : ''}
    
    overflow: hidden;
    align-items: center;
    ${(props) => props.noShrink ? 'flex-shrink: 0;' : ''}
    .icon {
        vertical-align: middle;
        font-weight: 700;
    }
    .icon--rotate {
        transform: rotate(90deg);
    }
    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: var(--ui_buttons_change_location_size);
    }
    &.location-btn {
        border: none;
        span {
            border-bottom: 1px solid var(--ui_buttons_change_location_color);
        }
        padding-left: 0;
    }
`;

export default MenuFilterRow;
