import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import mixpanel from "mixpanel-browser";


type PrivacyModalProps = {
    show: boolean;
    onHide: () => void;
};

const PrivacyModal = ({
    show,
    onHide,
}: PrivacyModalProps) => {

    useEffect(() => {

        document.getElementById('leaving-modal')?.classList.add('anim-enter');
        if ( show ) {
            mixpanel.track('viewed privacy');
        }
        return () => {
            document.getElementById('leaving-modal')?.classList.remove('anim-enter');
        };

    }, [show]);



    return (
        <TermsModalWrapper
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            centered
            animation={false}
            id={'leaving-modal'}
        >
            <Modal.Header className="">
                <h4 className='modal__title'>Privacy Policy</h4>
                <div className="modal-close__wrapper">
                    <button role="button" className={'modal-close__btn'} onClick={() => onHide()}>
                        <i className="feather feather-x feather-18 brand-color-filter" />
                    </button>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-body-container">

                    <h2>Introduction</h2>
                    <p>This is the privacy and cookies policy for www.orderswift.com (Site) and for the ordering and payment service allowing you to order and pay for food online from your selected restaurant, eatery, café, deli, pub or food truck (Service) (together Site and Platform shall be referred to as the <strong>orderswift Systems</strong>). The orderswift Platform is operated by Tasty Tech Limited trading as Orderswift (<strong>we</strong>, <strong>us</strong> and <strong>our</strong>).</p>

                    <p>The GDPR defines "personal data" as any information relating to an identified or identifiable natural person (a “data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.</p>

                    <p>By using the Orderswift Systems, you consent to the collection and use of information in accordance with this privacy policy.
                        We reserve the right to change this privacy policy from time to time by changing it on the orderswift Systems. This privacy policy was last updated in May 2018.</p>



                    <h2>Information we may collect from you</h2>
                    <p>We may collect and process the following information about you:</p>
                    <ul>
                        <li><p>information (such as your name, geographical location, IP address, email address, postal address and telephone number) that you provide if you use the orderswift Systems, including if you are remembered as a user of the orderswift Systems, subscribe to any service, upload or submit any material via the orderswift Systems, request any information, or enter into any competition or promotion we may sponsor;</p></li>
                        <li><p>details of any transactions made by you through the orderswift Systems</p></li>
                        <li><p>communications you send to us, for example to report a problem or to submit queries, concerns or comments regarding the orderswift Systems or its content; and</p></li>
                        <li><p>information from surveys that we may, from time to time, run on the orderswift Systems for research purposes, if you choose to respond to, or participate in, them.</p></li>
                    </ul>

                    <p>You are under no obligation to provide any such information. However, if you should choose to withhold requested information, we may not be able to provide you with certain services.</p>

                    <p>When you visit the orderswift Systems, we may automatically collect additional information about you, such as the type of internet browser you use, any website from which you have come to the orderswift Systems and your IP address (a unique string of numbers separated by periods that identifies each computer using the Internet Protocol to communicate over a network) which is automatically recognised by our web server. You cannot be identified from this information and it is only used to assist us in providing an effective service on the orderswift Systems and to collect broad demographic information for aggregate use.</p>


                    <h2>Adequate, Relevant, and Limited Data Processing</h2>
                    <p>The Company will only collect and process personal data for and to the extent necessary for the specific purpose or purposes of which data subjects have been informed.</p>


                    <h2>Cookies</h2>
                    <p>When you interact with the orderswift Systems, we try to make that experience simple and meaningful. When you visit the orderswift Systems, our web server sends a cookie to your computer. Cookies are small pieces of information which are issued to your computer when you visit a website and which store and sometimes track information about your use of the orderswift Systems. A number of cookies we use last only for the duration of your web session and expire when you close your browser. Other cookies are used to remember you when you return to the orderswift Systems and will last for longer.</p>

                    <p>We use cookies to:</p>
                    <ul>
                        <li><p>remember that you have visited us before; this means we can identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get;</p></li>
                        <li><p>customise elements of the promotional layout and/or content of the pages of the orderswift Systems;</p></li>
                        <li><p>collect anonymous statistical information about how you use the orderswift Systems (including how long you spend on the orderswift Systems) and where you have come to the orderswift Systems from, so that we can improve the orderswift Systems and learn which parts of the orderswift Systems are most popular with visitors; and</p></li>
                        <li><p>gather information about the pages on the orderswift Systems that you visit, and also other information about other websites that you visit, so as to place you in a "market segment". This information is only collected by reference to the IP address that you are using but does include information about the county and city you are in, together with the name of your internet service provider. This information is then used to place interest-based advertisements on the orderswift Systems which it is believed will be relevant to your market segment. For more information about this type of interest based advertising, and about how to turn this feature off please visit www.youronlinechoices.co.uk</p></li>
                    </ul>

                    <p>Some of the cookies used by the orderswift Systems are set by us, and some are set by third parties who are delivering services on our behalf.</p>

                    <p>Most web browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting www.allaboutcookies.org which includes additional useful information on cookies and how to block cookies using different types of browser. Please note, however, that by blocking or deleting cookies used on the orderswift Systems, you may not be able to take full advantage of the orderswift Systems.</p>

                    <p>In addition to cookies, tracking pixels may be set by us or third parties in respect of your use of the orderswift Systems. Tracking pixels are small image files within the content of the orderswift Systems or the body of our newsletters so we or third parties can understand which parts of the orderswift Systems are visited and whether particular content is of interest.</p>


                    <h2>Uses made of your information</h2>
                    <p>We will use the information you provide to:</p>
                    <ul>
                        <li><p>enable us and the restaurants that use our Platform to process your food orders and take payment and provide you with any other services and information offered through the orderswift Systems and which you request;</p></li>
                        <li><p>remember your details;</p></li>
                        <li><p>verify and carry out financial transactions in relation to payments that are made online;</p></li>
                        <li><p>share your details and order with the restaurant with which you have placed your order. The restaurants use of that data will be subject to the restaurants own privacy policy;</p></li>
                        <li><p>audit the downloading of data from the orderswift Systems;</p></li>
                        <li><p>improve the layout and/or content of the pages of the orderswift Systems and customise them for users;</p></li>
                        <li><p>identify visitors to the orderswift Systems;</p></li>
                        <li><p>carry out research on our users' demographics and tracking of sales data;</p></li>
                        <li><p>send you information we think you may find useful or which you have requested from us, including information about our products and services or those of carefully selected third parties, provided you have indicated that you do not object to being contacted for these purposes;</p></li>
                        <li><p>allow, with your consent, carefully selected third parties to send you information directly which you may find useful regarding their products and services.</p></li>
                    </ul>

                    <p>You can tell us not to contact you with information regarding our products and services or those of third parties or to share your details with third parties so that they can send you information regarding their products and services, either at the point such information is collected on the orderswift Systems (by checking or un-checking (as directed) the relevant box) or, where you do not wish us to continue to use your information in this way, by following the unsubscribe instructions on any communications sent to you. You can also exercise the right at any time by contacting us using the Contacting us details at the end of this privacy policy.</p>


                    <h2>Information sharing</h2>
                    <p>We may disclose aggregate statistics about visitors to the orderswift Systems, customers and sales in order to describe our services to prospective partners, advertisers, sponsors and other reputable third parties and for other lawful purposes, but these statistics will include no personally identifiable information. We will disclose your personal information to the restaurants from whom you ordered food. We will with your permission share your personal information with other restaurants who use our Platform.</p>

                    <p>We may disclose your personal information to any of our affiliates, or to our agents or contractors who assist us in providing the services we offer through the orderswift Systems, processing transactions, fulfilling requests for information, receiving and sending communications, updating marketing lists, analysing data, providing support services or in other tasks, from time to time. Our agents and contractors will only use your information to the extent necessary to perform their functions. In the event that we undergo re-organisation or are sold to a third party, you agree that any personal information we hold about you may be transferred to that re-organised entity or third party.</p>

                    <p>We may disclose your personal information if required to do so by law or if we believe that such action is necessary to prevent fraud or cyber crime or to protect the orderswift Systems or the rights, property or personal safety of any person.</p>



                    <h2>Accountability and Record-Keeping</h2>
                    <p>The Company’s Data Protection Officer is Candice Mast, Candice.mast@mastassociates.co. and by telephone on +44 (0) 208 720 6830.</p>

                    <p>The Data Protection Officer shall be responsible for overseeing the implementation of this Policy and for monitoring compliance with this Policy, the Company’s other data protection-related policies, and with the GDPR and other applicable data protection legislation.</p>

                    <p>The Company shall keep written internal records of all personal data collection, holding, and processing, which shall incorporate the following information:</p>

                    <p>The name and details of the Company, its Data Protection Officer, and any applicable third-party data processors;</p>

                    <p>The purposes for which the Company collects, holds, and processes personal data;</p>

                    <p>Details of the categories of personal data collected, held, and processed by the Company, and the categories of data subject to which that personal data relates;</p>

                    <p>Details of any transfers of personal data to non-EEA countries including all mechanisms and security safeguards;</p>

                    <p>Details of how long personal data will be retained by the Company (please refer to the Company’s Data Retention Policy); and</p>

                    <p>Detailed descriptions of all technical and organisational measures taken by the Company to ensure the security of personal data.</p>



                    <h2>Restaurants and External links</h2>
                    <p>We are not responsible for the privacy policies or the content of any restaurants who use the Platform or any external sites to which we may link from the Site.</p>



                    <h2>Payment processing</h2>
                    <p>Payment details you provide will be encrypted using secure sockets layer (SSL) technology before they are transmitted to us over the internet. Payments made through the orderswift System are made through our PCI compliant payment gateway provider, Stripe. You will be providing credit or debit card information directly to Stripe which operates a secure server to process payment details, encrypting your credit/debit card information and authorising payment. Information which you supply to Stripe is not within our control and is subject to Stripe's own privacy policy and terms and conditions.</p>



                    <h2>Security</h2>
                    <p>We place great importance on the security of all personally identifiable information associated with our users. We have security measures in place to attempt to protect against the loss, misuse and alteration of personal information under our control. For example, our security and privacy policies are periodically reviewed and enhanced as necessary and only authorised personnel have access to personal information. Whilst we cannot ensure or guarantee that loss, misuse or alteration of information will never occur, we use all reasonable efforts to prevent it.</p>

                    <p>You should bear in mind that submission of information over the internet is never entirely secure. We cannot guarantee the security of information you submit via the orderswift Systems whilst it is in transit over the internet and any such submission is at your own risk.</p>

                    <p>It is advisable to close your browser when you have finished your user session to help ensure others do not access your personal information if you use a shared computer or a computer in a public place.</p>



                    <h2>Storage of your information</h2>
                    <p>Information that you submit via the orderswift Systems will only be stored for as long as we legitimately need it or as required by the law. This information is sent to and stored on secure servers located in the United Kingdom. This is necessary in order to process the information. Information submitted by you may be transferred by us to our other offices and/or to the third parties mentioned in the circumstances described above (see Information sharing), which may be situated outside the European Economic Area (EEA) and may be processed by staff operating outside the EEA. The countries concerned may not have similar data protection laws to the EEA. Where we transfer your information, we will take all reasonable steps to ensure that your privacy rights continue to be protected.</p>



                    <h2>Data Breach Notification</h2>
                    <p>All personal data breaches must be reported immediately to the Company’s Data Protection Officer.</p>

                    <p>If a personal data breach occurs and that breach is likely to result in a risk to the rights and freedoms of data subjects (e.g. financial loss, breach of confidentiality, discrimination, reputational damage, or other significant social or economic damage), the Data Protection Officer must ensure that the Information Commissioner’s Office is informed of the breach without delay, and in any event, within 72 hours after having become aware of it.</p>

                    <p>In the event that a personal data breach is likely to result in a high risk to the rights and freedoms of data subjects, the Data Protection Officer must ensure that all affected data subjects are informed of the breach directly and without undue delay.</p>

                    <p>Data breach notifications shall include the following information:</p>
                    <ul>
                        <li><p>The categories and approximate number of data subjects concerned;</p></li>
                        <li><p>The categories and approximate number of personal data records concerned;</p></li>
                        <li><p>The name and contact details of the Company’s data protection officer (or other contact point where more information can be obtained);</p></li>
                        <li><p>The likely consequences of the breach;</p></li>
                        <li><p>Details of the measures taken, or proposed to be taken, by the Company to address the breach including, where appropriate, measures to mitigate its possible adverse effects.</p></li>
                    </ul>



                    <h2>Your rights</h2>
                    <p><strong>Data Subject Access</strong></p>

                    <p>Data subjects may make subject access requests (“SARs”) at any time to find out more about the personal data which the Company holds about them, what it is doing with that personal data, and why.</p>

                    <p>Responses to SARs shall normally be made within one month of receipt, however this may be extended by up to two months if the SAR is complex and/or numerous requests are made. If such additional time is required, the data subject shall be informed.</p>

                    <p>All SARs received shall be handled by the Company’s Data Protection Officer.</p>

                    <p>The Company does not charge a fee for the handling of normal SARs. The Company reserves the right to charge reasonable fees for additional copies of information that has already been supplied to a data subject, and for requests that are manifestly unfounded or excessive, particularly where such requests are repetitive.</p>

                    <h2>Contacting us</h2>
                    <p>Please submit any questions, concerns or comments you have about this privacy policy or any requests concerning your personal data by email to support@orderswift.com.</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="">

                <div className="footerContent">

                </div>
            </Modal.Footer>
        </TermsModalWrapper>
    );
};

const TermsModalWrapper = styled(Modal)`
    .modal-body {
        overflow-y: auto;

        .modal-body-container {
            padding: 20px;
            margin-top: 64px;
            p {
                margin-bottom: 0;
            }
        }

    }
    .modal-header {
        display: flex;
        align-items: center;
        > div { width: 100%}
        background-color: white;
        z-index: 1;

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 16px 20px;
        min-height: 64px;

        .modal__title {
            margin-bottom: 0;
            transition: opacity 0.2s ease-in-out;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 40px;
        }
    }

    .modal-body {
        padding: 0;
        
        h2 {
            margin-top: 16px;
        }
    }



    .close {
        float: left;
        opacity: 1;
    }



    .modal-footer {
        padding: 16px 20px !important;
        box-shadow: none !important;

        .footerContent {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            flex-direction: column;
            gap: 16px;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }

    }
    .modal-dialog .modal-content {
        border-radius: 4px;
        max-width: 560px;
        height: auto;
        margin: 20px;
    }

`;

export default PrivacyModal;
