import React from 'react';
import styled, { keyframes } from 'styled-components';


type SpinnerProps = {
    show: boolean;
    color?: string;
};

const PageSpinnerTwo = ({ show = false, color}: SpinnerProps) => {
    return (
        <Ring show={show} color={color}>
            <div></div>
        </Ring>
    );
};

const rotateAnimation = keyframes`
    0% {  transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
`;

const Ring = styled.div<{ show: boolean, color: string | undefined }>`
    ${ (props) => props.show ? 'display:  block;' : 'display: none;' }
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    
    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: calc(100%);
        height: calc(100%);
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        animation: ${rotateAnimation} 1s linear infinite;
        transform-origin: center center;
        border-color: ${(props) => props.color};
        border-bottom-color: transparent;
    }
   
`;

export default PageSpinnerTwo;
