import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import mixpanel from "mixpanel-browser";
import {CTA} from "../../../general/Button";
import {useNavigate} from "react-router-dom";
import TinyModal from "../../../general/TinyModal";


type AgeModalProps = {
    show: boolean;
    onHide: () => void;
};

const AgeRestrictionModal = ({
    show,
    onHide,
}: AgeModalProps) => {

    const renderFooter = () => (
        <>
            <CTA secondary buddies
                 onClick={() => navigate(-1)}
            >
                <span>Go back</span>
            </CTA>
            <CTA buddies
                 onClick={onHide}>
                <span>Continue</span>
            </CTA>
        </>
    )

    const navigate = useNavigate();

    return (
        <TinyModal
            show={show}
            onHide={onHide}
            modalID={'age-modal'}
            footer={renderFooter()}
        >
            <>
                <h4 className='modal__title'>Ordering alcohol</h4>
                <p>By continuing, you are confirming you are over 18. Please have your ID ready – this can be a passport, driving licence or official photo ID (with pass hologram) – to show you’re over 18.</p>
            </>
        </TinyModal>
    );
};


export default AgeRestrictionModal;
