import React from 'react';
import styled from 'styled-components';
import logo from '../../../assets/images/orderswift_logo.svg';


type GenericErrorPageProps = {
    title: string;
    message: string;
};

const GenericErrorPage = ({ title, message }: GenericErrorPageProps) => {
    return (
        <Wrapper>
            <MessageWrapper>
                {/*<FontAwesomeIcon icon={icon} size="5x" />*/}
                <h2 className="my-4">{title}</h2>
                <p dangerouslySetInnerHTML={{__html: message}}/>
            </MessageWrapper>
            <div className="absolute pin bg-no-repeat md:bg-left lg:bg-center img-container" style={{ overflow: 'hidden' }}>
                <img src={logo} alt='Logo'/>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-container img {
        width: 100%;
        position: absolute;
        right: -50%;
        bottom: -20%;
        filter: grayscale(100%);
        opacity: 0.2;

        @media screen and (min-width: 768px) {
            background-position: left;
        }

        @media screen and (min-width: 1024px) {
            background-position: center;
            bottom:auto;
            top: 10%;
        }
    }
`;

const MessageWrapper = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 15px;
    background-color: white;
    padding: 2rem 1rem;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0px 4px 52px rgba(0, 0, 0, 0.1);
    z-index: 100;
`;

export default GenericErrorPage;
