import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { t } from 'ttag';
import useBasketContext from '../../../contexts/basket/useBasketContext';
import useSiteContext from '../../../contexts/site/useSiteContext';
import useOrderContext from "../../../contexts/order/useOrderContext";
import useOperatorContext from "../../../contexts/operator/useOperatorContext";
import PageSpinnerThree from '../../general/PageSpinnerThree';
import AppLayout from '../../layouts/app/AppLayout';
import Basket from '../Menu/components/basket/Basket';
import CustomerForm from './components/CustomerForm';
import ScrollToTop from '../../../hooks/scrollToTop';
import { Elements as StripeElementProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AgeRestrictionModal from "./components/AgeRestrictionModal";
import AllergensModal from "./components/AllergensModal";

const CheckoutPage = () => {

    const navigate = useNavigate();
    const operatorContext = useOperatorContext();
    const siteContext = useSiteContext();
    const basketContext = useBasketContext();
    const orderContext = useOrderContext();

    const [ageModalShow, setAgeModalShow] = useState(false);
    const [allergensModalShow, setAllergensModalShow] = useState(false);

    useEffect(() => {
        if (! basketContext.validatedBasket?.basket ) {
            navigate('/');
        } else {

            // allergens come first..
            if ( (operatorContext.operator?.allergen_info?.length ?? '') > 0 ) {
                setAllergensModalShow((operatorContext.operator?.allergen_info?.length ?? '') > 0);
            } else {
                setAgeModalShow(basketContext.validatedBasket.has_age_restricted_item);
            }


        }
    }, []);

    if (!siteContext || !basketContext || basketContext.validatedBasket === undefined) {
        return <PageSpinnerThree show={true} />;
    }

    if (siteContext.loading || basketContext.loading) {
        return <PageSpinnerThree show={true} />;
    }

    if (!operatorContext || !operatorContext.operator || !siteContext) {
        return <PageSpinnerThree show={true} />;
    }

    if (operatorContext.loading || siteContext.loading) {
        return <PageSpinnerThree show={true} />;
    }


    let key = operatorContext.operator?.allow_stripe_test ? process.env.REACT_APP_STRIPE_KEY_TEST : process.env.REACT_APP_STRIPE_KEY;
    const stripePromise = loadStripe(key ?? '');

    return (
        <AppLayout page="checkout">
            <StripeElementProvider stripe={stripePromise}>
                <ScrollToTop />
                <Helmet>
                    <title>{t`Checkout - Powered by orderswift`}</title>
                </Helmet>

                <StyledSpinnerOverlay show={orderContext.loading}>
                    <PageSpinnerThree show={true} />
                    <div className={'pay_loading__content'}>
                        <div className={'pay_loading__logo'}/>
                        <p>Processing payment...</p>
                    </div>
                </StyledSpinnerOverlay>

                {/*<AllergensModal
                    show={allergensModalShow}
                    onHide={() => {
                        setAllergensModalShow(false);
                        setAgeModalShow(basketContext.validatedBasket?.has_age_restricted_item ?? false);
                    }}
                />*/}

                <AgeRestrictionModal
                    show={ageModalShow}
                    onHide={() => setAgeModalShow(false)}
                />
                <StyledContainer>
                    <StyledRow>
                        <StyledOrderDetails>
                            <Basket checkout={true} reset={false} site={basketContext.validatedSite} />
                        </StyledOrderDetails>
                        <StyledPayment>
                            <CustomerForm />
                        </StyledPayment>
                    </StyledRow>
                </StyledContainer>
            </StripeElementProvider>
        </AppLayout>
    );
};

const StyledSpinnerOverlay = styled.div<{show: boolean}>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FFFFFF;
    transition: opacity 0.1s ease-in-out;
    z-index: 999;
    align-items: center;
    justify-content: center;
    
    .pay_loading__content {
         margin-top: 112px;
         text-align: center;
         .pay_loading__logo {
            background-image: var(--logo);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            margin: 0 auto; 
            max-width: 85vw;
            display: block;
            cursor: pointer;
            height: 33px;
            width: 214px;
        }
        p {
            font-size: 16px;
            line-height: 22px;
            margin-top: 12px;
            margin-bottom: 0;
            display: inline-block;
            font-family: var(--text_ui_font);
            font-weight: var(--text_ui_weight);
            text-transform: var(--text_ui_case);
            color: var(--brand);
        }
    }
    display: ${(props) => props.show ? 'flex' : 'none'};
`;

const StyledContainer = styled.div`
    max-width: 1092px;
    background-color: var(--bg-grey);
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 993px) {
        padding: 0 20px;
        background-color: var(--white);
    }
`;

const StyledRow = styled.div`
    @media screen and (min-width: 993px) {
        padding: 48px 0;
        display: flex;
        gap: 56px;
        align-items: flex-start;
    }
    @media screen and (min-width: 1140px) {
        gap: 114px;
    }

`;
const StyledOrderDetails = styled.div`
    align-self: flex-start;
    width: 100%;
    margin-bottom: 16px;
    @media screen and (min-width: 993px) {
        order: 2;
        max-width: 432px;
        border: 1px solid ${(props) => props.theme.color_border};
        border-color: var(--border-grey);
        border-radius: 8px;
        padding: 20px;
        position: sticky;
        top: 112px;
    }
`;
const StyledPayment = styled.div`
    width: 100%;
    // border-top: 1px solid var(--border-grey);
    @media screen and (min-width: 993px) {
        order: 1;
        padding: 0;
        border-top: 0;
    }
`;
export default CheckoutPage;
