import styled from 'styled-components';
import toaster from 'toasted-notes';
import Alert from '../components/general/Alert';
import mixpanel from "mixpanel-browser";

interface NotificationProps {
    error: string;
    onClose: () => void;
}

const CustomNotification = ({ error, onClose }: NotificationProps) => {

    mixpanel.track('alert shown', {
        'message': error
    });

    return (
        <StyledAlert onClose={onClose} dismissible>
            <>{error}</>
        </StyledAlert>
    );
};

export const toastError = (errorMessage: string, duration?: number) => {
    toaster.notify(({ onClose }) => <CustomNotification error={errorMessage} onClose={onClose} />, {
        duration: null,
    });
};

const StyledAlert = styled(Alert)`
    padding-right: 45px;
    text-align: left;
    position: relative;
    padding: 12px 36px 12px 20px;
    background-color: var(--invalid);
    color: #FFFFFF;
    border-radius: 6px;
    > div {
        line-height: 20px;
        font-size: 14px;
        font-weight: 500;
    }
    > .close {
        padding: 6px 8px;
        opacity: 1;
        font-weight: 500;
        right: 4px;
        &:hover {
            color: var(--border-grey);
        }
    }
`;
