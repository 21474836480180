import api, { handleError, handleResponse } from '../';
import { TChannel } from '../../../contexts/operator/operator-context';
import mixpanel from "mixpanel-browser";

export const getMenu = (
    operatorHandle: string,
    id: string,
    channel: TChannel,
    flow: string,
    tableNumber?: string,
    postcode?: string,
) => {
    let params: any = {
        site_handle: id,
        channel,
        flow,
        handle: operatorHandle,
        distinct_id: mixpanel.get_distinct_id()
    };

    if (tableNumber || typeof tableNumber === 'string') {
        params.table_number = tableNumber;
    }

    if (postcode || typeof postcode === 'string') {
        params.postcode = postcode;
    }

    return api
        .get('menu', { params: params })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const getSites = (handle: string, flow: string) => {
    return api
        .get('sites', { params: { handle, flow } })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const filterSites = (handle: string, search: string) => {
    return api
        .get('sites/filter', { params: { handle, search } })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
};

export const validatePostcode = (handle: string, postcode: string|undefined, flow: string, channel: string, basket_id?: string) => {
    let params: any = {
        handle, postcode, flow, channel
    }

    if (basket_id ) {
        params.basket_id = basket_id
    }

    return api
        .get('postcode/validate', { params: params })
        .then((response) => {
            return handleResponse(response);
        })
        .catch((error) => {
            console.error('validatePostcode: ', error);
            return handleError(error)
        });
};

export const validateTable = (handle: string, table: string, flow: string) => {
    return api
        .get('table/validate', { params: { handle, table, flow} })
        .then((response) => {
            return handleResponse(response);
        })
        .catch((error) => {
            console.error('validateTable: ', error);
            return handleError(error)
        });
};