import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import config from '../../config/channels';
import { operatorName } from '../../config/operator';
import { useLocalStorage } from '../../hooks/localstorage';
import { APIErrorCode } from '../../services/api';
import { getTimeslots } from '../../services/api/basket';
import { getMenu, getSites } from '../../services/api/site';
import useBasketContext from '../basket/useBasketContext';
import { TChannel } from '../operator/operator-context';
import useOperatorContext from '../operator/useOperatorContext';
import { MenuData, SiteContext, SiteContextData, Timeslot, TMenu, TSite } from './site-context';
import {iLog} from "../../index";
import mixpanel from "mixpanel-browser";

const SiteContextProvider = ({ children }: { children: JSX.Element }) => {
    const [site, setSite] = useState<TSite | null>(null);
    const [menu, setMenu] = useState<TMenu | null>(null);
    const [timeslots, setTimeslots] = useState<Timeslot[] | null>(null);
    const [flow, setFlow] = useState('noflow');

    const [sites, setSites] = useState<TSite[] | null>(null);
    const [loading, setLoading] = useState(false);

    const [channel, setChannel] = useState<TChannel>('collection');
    const [savedChannel, setSavedChannel] = useLocalStorage('channel', 'collection');

    const basketContext = useBasketContext();
    const operatorContext = useOperatorContext();

    const navigate = useNavigate();

    useEffect(() => {
        if (savedChannel) {
            iLog('syncing saved channel with local', savedChannel);
            setChannel(savedChannel);
        }
    }, []);

    useEffect(() => {
        iLog('setting saved channel', channel);
        setSavedChannel(channel);
    }, [channel]);

    // how do we set the saved channel?

    const fetchSite = (
        id: string,
        channel: TChannel,
        flow: string = '-',
        handle: string = operatorName,
        tableNumber?: string,
        postcode?: string,
    ) => {
        setLoading(true);

        //iLog('Fetching site...');
        //getMockSite(id).

        if (operatorContext.operator) {
            flow = operatorContext.operator.flow ?? '';
        }

        // splitting out into each data concern - pass down in this context individually (timeslot, menu, site) = whole app needs updating

        getMenu(handle, id, channel, flow, tableNumber, postcode)
            .then((data: MenuData) => {
                // TODO IF  SITE IS NOT FOUND HERE, THEN REDIRECT TO 404?? Maybe API needs to 404?

                iLog('Site fetched:', [id, data]);
                setSite(data.site);
                setMenu(data.menu);
                setFlow(data.flow ?? 'noflow');

                // removed 20211011:
                // Albert:
                // because the API was changed, and it was decided to not return timeslots in the call..
                //setTimeslots(data.timeslots);

                if (tableNumber) {
                    basketContext.setTableNumber(tableNumber);
                }

                mixpanel.register({
                    'site': data.site?.name ?? '',
                    'menu': data.menu?.name ?? '',
                    'type': channel
                });

            })
            .catch((error) => {
                iLog('Issue fetching menu', [error.code, error.code && [APIErrorCode.TableNotFound].includes(error.code)]);
                if (error.code && [APIErrorCode.TableNotFound].includes(error.code)) {
                    navigate(`/${config.dineIn.handle}`);
                    console.warn('Table not found.');
                } else {
                    navigate('/404');
                }
            })
            .finally(() => {
                setLoading(false)
            });
    };

    const fetchSites = (handle: string, flow: string) => {
        setLoading(true);

        getSites(handle, flow)
            //.then((s: TSite[]) => {
            .then((data) => {
                iLog('getSites', data);
                //iLog('getSites', data.flow);
                setSites(data);
                setFlow(data.flow ?? 'noflow');
            })
            .catch((error) => {
                console.error('issue fetching sites');
                navigate('/500');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchTimeslots = (siteHandle: string, flow: string) => {
        //iLog('fetch timeslots', siteHandle);

        if (!siteHandle) {
            return;
        }

        getTimeslots(operatorName, siteHandle, channel, flow)
            .then((timeslots) => {
                iLog('timeslots received', timeslots);
                setTimeslots(timeslots.timeslots);
                setFlow(timeslots.flow ?? 'noflow');
            });
    };

    const validateTable = (siteHandle: string, flow: string, table: string) => {

        if (!siteHandle) {
            return;
        }

        getTimeslots(operatorName, siteHandle, channel, flow)
            .then((timeslots) => {
                iLog('timeslots received', timeslots);
                setTimeslots(timeslots.timeslots);
                setFlow(timeslots.flow ?? 'noflow');
            });
    };

    let siteContextValue: SiteContextData = {
        site,
        menu,

        timeslots,
        setTimeslots,
        fetchTimeslots,

        fetchSite,

        sites,
        fetchSites,

        loading,
        setLoading,

        channel,
        setChannel,

        flow,
        setFlow,
    };
    return <SiteContext.Provider value={siteContextValue}>{children}</SiteContext.Provider>;
};

export default SiteContextProvider;
