import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import powered_by from '../../../../assets/images/powered-by-orderswift-white.svg';

import { TPage } from '../AppLayout';

interface BannerProps {
    page: TPage;
}

const OrderswiftBanner = ({ page }: BannerProps) => {
    const [show, setShow] = useState(true);
    const [fading, setFading] = useState(false);

    const size = useWindowSize();

    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: 0,
            height: 0,
        });
        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    useEffect(() => {
        if ( size.width < 768 ) {

            const timeout = setTimeout(() => {
                setFading(true);
                setTimeout(() => setShow(false), 500);
            }, 5000);
            return () => clearTimeout(timeout);

        }
    }, [size])

    const pageBannerShown = () => {
        switch (page){
            case "channels" :
                return true
            case "menu" :
                return false
            case "sites" :
                return true
            default:
                return false
        }
    }

    if (show && pageBannerShown()) {
        return (
            <StyledBanner
                href="https://orderswift.com"
                target="_blank"
                className={fading ? 'fading' : ''}>
                <img width={165} height={28} src={powered_by} alt={'Powered by Orderswift'} />
            </StyledBanner>
        );
    } else if (page === 'menu-new') {
        return (
            <StyledBannerForMenu
                href="https://orderswift.com"
                target="_blank"
                className={fading ? 'fading' : ''}>
                <img width={165} height={28} src={powered_by} alt={'Powered by Orderswift'} />
            </StyledBannerForMenu>
        );
    } else {
        return null;
    }

};

const fadeAnim = keyframes`
 0% { opacity: 1 }
 100% { opacity: 0 }
`

const StyledBanner = styled.a`
    background-color: ${(props) => props.theme.color_os_blue};
    padding: 1px 10px;
    border-radius: 0;
    border: 0;
    margin: 0;
    z-index: 1001;
    position: fixed;
    top: 0;
    right: 0;
    border-bottom-left-radius: 4px;
    @media screen and (min-width: 768px) {
        box-shadow: 0 8px 16px rgba(0,0,0,0.1);
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 4px;
    }
    img {
        width: 165px;
        height: 28px;
        display: none;
    }
    &.fading {
        opacity: 0;
        animation-name: ${fadeAnim};
        animation-duration: 0.5s;
        animation-iteration-count: 1;
    }
`;

const StyledBannerForMenu = styled.a`
    background-color: ${(props) => props.theme.color_os_blue};
    padding: 1px 10px;
    border-radius: 0;
    border: 0;
    margin: 0;
    z-index: 1001;
    position: absolute;
    top: auto;
    right: 0;
    bottom: -30px;
    border-bottom-left-radius: 4px;
    display: none;
    @media screen and (min-width: 768px) {
        box-shadow: 0 8px 16px rgba(0,0,0,0.1);
        top: auto;
        right: 0;
        bottom: -30px;
        left: auto;
        border-bottom-left-radius: 4px;
    }
    img {
        width: 165px;
        height: 28px;
    }
    &.fading {
        opacity: 0;
        animation-name: ${fadeAnim};
        animation-duration: 0.5s;
        animation-iteration-count: 1;
    }
`;

export default OrderswiftBanner;
