import React from 'react';
import { Alert as BSAlert } from 'react-bootstrap';
import styled from 'styled-components';

type AlertProps = {
    icon?: string;
    iconColor?: string;
    variant?: string;
    className?: string;
    dismissible?: boolean;
    onClose?: () => void;
    children: JSX.Element|JSX.Element[];
};

const Alert = ({
     icon,
     iconColor,
     variant,
     children,
     className,
     dismissible,
     onClose }: AlertProps) => {
    return (
        <StyledAlert variant={variant} className={className} dismissible={dismissible} onClose={onClose}>
            {icon ? (
                <div className="alert__icon">
                    <i className={`feather feather-${icon} feather-20 ${iconColor ? 'color-filter-' + iconColor : '' }`} />
                </div>
            ) : null}
            <div>{children}</div>
        </StyledAlert>
    );
};

const StyledAlert = styled(BSAlert)`
    background-color: transparent;
    color: var(--invalid);

    border-radius: 10px;
    border: 0;
    display: flex;
    padding: 0 8px;
    position: static;
    a {
        font-weight: 600;
        text-decoration: underline;
    }
    .alert__icon {
        padding-right: 8px;
    }
    p {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
`;

export default Alert;
