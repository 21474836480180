import React from 'react';
import {AppContext, AppContextData} from "./app-context";

const AppContextProvider = ({ children }: { children: JSX.Element }) => {

    const AssetManifestURL = '//' + window.location.hostname + ( process.env.REACT_APP_ENV === 'local' ? ':3000' : '' ) + '/asset-manifest.json' ?? '';

    const setInitialAssetManifest = async () => {
        try {
            let resp = await fetch(AssetManifestURL, { method: 'get', mode: 'cors' });
            let obj = await resp.json();
            window.sessionStorage.setItem('manifest', JSON.stringify(obj));
        } catch (err) {
            console.error('AssetManifest', err);
        }
    }

    let AppContextValue: AppContextData = {
        setInitialAssetManifest
    };
    return <AppContext.Provider value={AppContextValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
