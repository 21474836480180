import React, {useState} from "react";
import styled from "styled-components";
import useOrderContext from "../../../../contexts/order/useOrderContext";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";
import PageSpinnerThree from "../../../general/PageSpinnerThree";
import {t} from "ttag";
import {CTA} from "../../../general/Button";
import HelpModal from "./HelpModal";

const Actions = () => {

    const operatorContext = useOperatorContext();
    const orderContext = useOrderContext();

    const order = orderContext.order;

    const [helpModalShow, setHelpModalShow] = useState(false);

    if (!orderContext || !order || orderContext.loading) {
        return null;
    }


    const defaultView = () => {

        if (!orderContext || !orderContext.order || orderContext.loading) {
            return null;
        }

        const encodedLocation = encodeURIComponent(
            orderContext.order.site?.address_1 + ', '
            + orderContext.order.site?.address_city + ', '
            + orderContext.order.site?.address_postcode);

        const directionsUrl = 'https://www.google.com/maps/dir/?api=1&destination='
            + encodeURI(orderContext.order.site?.address_1 + ', '
                + orderContext.order.site?.address_city + ', '
                + orderContext.order.site?.address_postcode);

        return (
            <>
                <div className={'conf__map'}>
                    <iframe
                        src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyCaDHOPEJyMCctOQ5BhW1uUXSuIspSVsfc&q=" + encodedLocation}
                        width="100%" height="150" frameBorder="0" style={{border: 0}} allowFullScreen={false}
                        aria-hidden="false" tabIndex={0}></iframe>

                </div>
                <StyledBlock className={'conf__map-content'}>
                    <h4 className={'conf__heading'}>{operatorContext.operator?.name} - {orderContext.order?.site.name}</h4>
                    <div className={'conf__site-detail'}>
                        {/*<i className={'conf__site-detail__icon feather feather-map-pin feather-20'}/>*/}
                        <div className={'conf__site-detail__text'}>
                            <span>{orderContext.order.site?.address_1}, {orderContext.order.site?.address_city} {orderContext.order.site?.address_postcode}</span>
                            <a href={directionsUrl} className={'conf__site-detail__link'} target={'_blank'}>Get
                                directions</a>
                        </div>
                    </div>
                    <div className={'conf__site-detail'}>
                        {/*<i className={'conf__site-detail__icon feather feather-phone feather-20'}/>*/}
                        <div className={'conf__site-detail__text'}>
                            <a href={'tel:' + orderContext.order.site?.phone?.replaceAll(' ', '')}>{orderContext.order.site?.phone ?? ''}</a>
                        </div>
                    </div>
                </StyledBlock>
            </>
        );
    }


    if (orderContext.order?.channel === 'delivery') {

        let address = order.delivery.destination;

        return (
            <>

                <HelpModal
                    show={helpModalShow}
                    onHide={() => setHelpModalShow(false)}
                    siteTel={order.site.phone}
                    provider={orderContext.order.delivery.provider}
                    providerTel={orderContext.order.delivery.provider_tel}
                    providerLink={orderContext.order.delivery.provider_link}
                />

                <StyledBlock className={'delivery-wrap'}>
                    <Delivery>
                        <h4 className={'conf__heading'}>ORDER {order.ref.substring(9)}</h4>
                        <div className={'delivery-header'}>Delivery</div>
                        <div className={'delivery-address'}>{address}</div>
                        <div className={'delivery-buttons'}>
                            <CTA
                                onClick={() => setHelpModalShow(true)}
                                block
                                secondary
                            >
                                <div className={'btn-wrapper'}>
                                    <i className="feather feather-info feather-20"/>
                                    ORDER HELP
                                </div>
                            </CTA>
                        </div>
                    </Delivery>
                </StyledBlock>
            </>
        );
    }

    return defaultView();
}

const StyledBlock = styled.div<{ bg_var?: string, borderTop?: boolean, noMobilePadding?: boolean }>`
    width: 100%;
    ${(props) => props.noMobilePadding ? null : "padding: 20px;"}
    background-color: var(--${(props) => props.bg_var ?? 'white'});
    ${(props) => props.borderTop ? 'border-top: 1px solid var(--border-grey);' : null} 
    @media screen and ( min-width: 993px) { padding: 20px; }
    
    &.delivery-wrap {
        padding: 20px;
        border: 1px solid var(--border-grey);
        border-radius: 8px;
    }
    
`;

const Delivery = styled.div`
    
    font-size: 16px;
    line-height: 24px;
    color: var(--text-black);
    
    .delivery-header {
        padding-bottom: 12px;
        font-weight: 500;
    }
    .delivery-address {
        
    }
    .delivery-buttons {
        padding: 20px 0 0;
        
        .btn-wrapper {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
        }
    }
    
`;

export default Actions;
