import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import mixpanel from "mixpanel-browser";
import useOperatorContext from "../../../../contexts/operator/useOperatorContext";
import Zlogo from "../../../../assets/images/Zizzi-ZillionairesClub-Blue.svg"
import {Form} from "react-bootstrap";
import {t} from "ttag";
import useBasketContext from "../../../../contexts/basket/useBasketContext";
import {postMember} from "../../../../services/api/basket";
import {CTA} from "../../../general/Button";
import PageSpinnerThree from "../../../general/PageSpinnerThree";
import {iLog} from "../../../../index";
import useSiteContext from "../../../../contexts/site/useSiteContext";
import useOrderContext from "../../../../contexts/order/useOrderContext";

type ZillionaireProps = {
    page: 'payment' | 'confirmation';
};

const Zillionaire = ({page}: ZillionaireProps) => {

    const operatorContext = useOperatorContext();
    const basketContext = useBasketContext();
    const siteContext = useSiteContext();
    const orderContext = useOrderContext();

    const [loading, setLoading] = useState(false);
    const [memberId, setMemberId] = useState('');
    const [rememberMember, setRememberMember] = useState(false);
    const [firstError, setFirstError] = useState('');

    useEffect(() => {
        let z = localStorage.getItem('zillionaire');
        if ( z ) {
            setMemberId(z);
            setRememberMember(true);
        }

    }, []);

    useEffect(() => {
        if ( rememberMember ) {
            localStorage.setItem('zillionaire', memberId);
        } else {
            localStorage.removeItem('zillionaire');
        }
    }, [rememberMember]);

    if ( !operatorContext.operator || !('zillionaire' in operatorContext.operator) ) {
        return null;
    }

    // Zs are only accrued on orders above 10gbp
    if ( page === 'confirmation' && ((orderContext.storedOrder?.total ?? 0) < 1000) ) {
        return null;
    }

    const validateMember = async () => {

        if ( memberId.length !== 10 ) {
            return;
        }

        setLoading(true);
        setFirstError('');


        await postMember(
            basketContext.validatedBasket?.basket_id ?? '',
            (document.getElementById('email') as HTMLInputElement).value,
            memberId,
            siteContext.flow ?? 'noflow'
        )
            .then((r) => {
                iLog('validatedMember', r);

                if ( rememberMember ) {
                    localStorage.setItem('zillionaire', memberId);
                }

                let code = operatorContext.operator?.zillionaire?.promo;

                if ( r.success && code ) {
                    basketContext.validatePromoCode(code, (document.getElementById('email') as HTMLInputElement).value)
                        .finally(() => {
                            setLoading(false);
                            mixpanel.track('Promo applied');
                        });
                }

            }).catch((error: any)=> {

                if ( error['email'] && error['email'].length > 0 && error['email'][0].length > 0 ) {
                    setFirstError(error['email'][0]);
                }

                setLoading(false);

            });

    }

    return (
        <ZillionaireWrapper>
            <Logo>
                <img src={Zlogo} alt="Zizzi Zillionaires Club" style={{width: '150px'}}/>
            </Logo>
            <TextContent
                dangerouslySetInnerHTML={{__html: (page === 'payment'
                        ? operatorContext.operator?.zillionaire?.payment
                        : operatorContext.operator?.zillionaire?.confirmation) ?? '' }}
            />
            { page === 'payment' ? (
                <>
                    <InputWrap>
                        <StyledGroup controlId="memberID"
                                     className="member"
                                     loading={loading.toString()}>
                            <Form.Control
                                placeholder="Enter Zillionaires' Club ID"
                                className=""
                                value={memberId}
                                maxLength={10}
                                onChange={(e) => setMemberId(e.target.value)}
                            >
                            </Form.Control>
                            <i className={'feather feather-user feather-20 member__icon'}/>
                            <CTA
                                className={`font-cta submit-member-btn ${ memberId.length < 10 ? 'submit-member-btn-disabled' : '' }`}
                                disabled={loading}
                                onClick={() => validateMember()}
                                block
                            >
                                <PageSpinnerThree show={loading} text={t`Add`} color={'#FFFFFF'} />
                            </CTA>
                        </StyledGroup>
                        { firstError.length > 0 && (
                            <FeedbackElement className={`invalid-feedback show-important`}>
                                {firstError}
                            </FeedbackElement>
                        )}
                    </InputWrap>
                    <RememberMe>
                        <Form.Group controlId="zillionaire_remember_me" className={'opt_in_group'}>
                            <Form.Control
                                type="checkbox"
                                name="zillionaire_remember_me"
                                className={'checkbox'}
                                checked={rememberMember}
                                onChange={ (e:React.ChangeEvent<HTMLInputElement>) => setRememberMember(e.target.checked)}
                            />
                            <svg
                                className={'text-white'}
                                width={14}
                                height={14}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.293 7.707l3.774 3.774 5.702-6.841-1.537-1.28-4.299 5.159-2.226-2.226-1.414 1.414z"
                                    fill="currentColor"/>
                            </svg>
                            <OptInLabel htmlFor={'zillionaire_remember_me'}>
                                Remember me for next time
                            </OptInLabel>
                        </Form.Group>
                    </RememberMe>
                </>
            ): null }
        </ZillionaireWrapper>
    );
};

const ZillionaireWrapper = styled.div`
    border-bottom: 1px solid var(--border-grey);
    margin-bottom: 16px;
    
    @media screen and (min-width: 768px) {
        border: 1px solid var(--border-input);
        border-radius: var(--card-radius);
        margin-bottom: 0;
    }
    background: white;
    padding: 16px;
`;

const Logo = styled.div`
    margin-bottom: 16px;
`;
const TextContent = styled.div`
    a {
        color: var(--text-black);
        text-decoration: underline;
    }
`;
const InputWrap = styled.div`
    margin-bottom: 16px;
`;
const FeedbackElement = styled.div`
    &.show-important { display:block !important; }
`;
const RememberMe = styled.div`

    .opt_in_group {
        display: flex;
        gap: 12px;
        margin-bottom: 0;
    
        > span {
            flex-grow: 1;
            font-size: 14px;
            line-height: 18px;
        }
    
        > div, > span {
            cursor: pointer;
        }
    
        label {
            color: var(--text-grey);
            margin-bottom: 0;
            user-select: none;
            font-size: 14px;
            line-height: 19px;
        }
    
        width: 100%;
        position: relative;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
    
        svg {
            pointer-events: none;
            position: absolute;
            top: 2px;
            left: 2px;
        }
    }


    .checkbox {
        padding: 0;
        height: 18px;
        width: 18px;
        flex-shrink: 0;
        margin: 0;
        border-radius: 4px;
            // border: 1px solid ${(props) => props.theme.v3.ui.other.link} !important;        
        border: 2px solid var(--border-input) !important;

        -moz-appearance: none;
        -webkit-appearance: none;
        -o-appearance: none;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
        cursor: pointer;

        &:checked {
                // accent-color: ${(props) => props.theme.v3.ui.other.link};
            background-color: ${(props) => props.theme.v3.ui.other.link};
            border: none !important;
        }

        &:focus:not([disabled]) {
            border-color: transparent !important;
            box-shadow: none !important;
        }

        &:focus:not(:checked) {
            border-color: var(--border-input) !important;
        }


        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
    }
`;

const OptInLabel = styled.label`
    cursor: pointer;
    line-height: 19px;
    input {
        display: none;
    }
    input:checked + label:before {
        background-color: blue;
    }
    i {
        margin-bottom: 4px;
    }
`;

const StyledGroup = styled(Form.Group)<{loading: string}>`
    width: 100%;
    margin: 0;
    position: relative;
    margin-top: 16px;
    .member-add {
        color: var(--brand);
        cursor: pointer;
        margin-top: 8px;
    }
    input {
        padding-left: 48px !important;
        &:focus ~ .promo__icon {
            color: var(--text-brand);
        }
        
        @media screen and (min-width: 768px) {
            min-width: 220px;
        }
    }
    button {
        width: auto;
    }
    .submit-member-btn {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        
        height: auto;
        padding: 4px;

        ${(props) => props.loading === 'true' ? 'background-color: var(--btn-grey) !important;' : 'background-color: transparent;'};
        ${(props) => props.loading === 'true' ? 'color: white !important' : 'color: #D77348;'};
        
        &.submit-member-btn-disabled {
            color: var(--text-grey);
            cursor: not-allowed;
        }
        
    }

    .member__icon {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--text-grey);
    }
`;

export default Zillionaire;
