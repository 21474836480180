import { useEffect, useState } from "react";

const useScrollPositionOnElement = (selector: string) => {

    const [elementScrollPosition, setElementScrollPosition] = useState(0);

    useEffect(() => {

        let el = document.querySelector(selector);

        const updateElementPosition = () => {
            let val = el?.scrollLeft ?? 0;
            setElementScrollPosition(val);
        }

        el?.addEventListener("scroll", updateElementPosition);
        updateElementPosition();

        return () => el?.removeEventListener("scroll", updateElementPosition);

    }, []);

    return elementScrollPosition;
};

export default useScrollPositionOnElement;