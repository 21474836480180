import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { CTA } from "../general/Button";
import config from "../../config/channels";
import mixpanel from "mixpanel-browser";

type TinyModalProps = {
    onHide: () => void;
    show: boolean;
    footer?: JSX.Element;
    html?: string;
    children?: JSX.Element;
    expandMobile?: boolean;
    showClose?: boolean;
    className?: string;
    scrollHeader?: boolean;
    modalID?: string | null;
    backdropClassName?: string;
};

const TinyModal = ({
    show,
    footer,
    children,
    modalID = null,
    html,
    onHide,
    showClose = true,
    backdropClassName,
    expandMobile = true,
    className,
}: TinyModalProps) => {

    //TODO - check


    useEffect(() => {
        if (modalID) {
            document.getElementById(modalID)?.classList.add('anim-enter');
            if ( show ) {
                mixpanel.track(`viewed ${modalID} modal`);
            }
        }
        return () => {
            if (modalID) { document.getElementById(modalID)?.classList.remove('anim-enter') }
        };
    }, [show]);


    return (
        <TinyModalWrapper
            show={show}
            onHide={onHide}
            size="lg"
            backdrop={true}
            centered
            animation={false}
            id={modalID}
            expand={expandMobile ? 1 : 0}
            backdropClassName={backdropClassName}
        >
            <Modal.Body>
                <div className={"modal-body-container" + (footer ? "" : " modal-body-container--end")}>
                    { showClose ? (
                        <div className={'modal-close__wrapper'}>
                            <button className={'modal-close__btn'} onClick={() => onHide()}>
                                <i className="feather feather-x feather-18 brand-color-filter" />
                            </button>
                        </div>
                    ) : null }
                    {html ? <div dangerouslySetInnerHTML={{ __html: html }}/> : children}
                </div>
            </Modal.Body>
            {footer ? (
                <Modal.Footer className="">
                    <div className="footerContent">
                        {footer}
                    </div>
                </Modal.Footer>
            ) : null}
        </TinyModalWrapper>
        // <StyledModal
        //     expand={expandMobile ? 1 : 0}
        //     show={show}
        //     onHide={onHide}
        //     size="lg"
        //     scrollHeader={scrollHeader}
        //     backdrop="static"
        //     className={className}
        //     centered
        // >
        //     {header ? <Modal.Header closeButton={showClose}>
        //         <StyledContainer>
        //             {header}
        //         </StyledContainer>
        //     </Modal.Header> : null}
        //     <Modal.Body>{html ? <div dangerouslySetInnerHTML={{ __html: html }}></div> : children}</Modal.Body>
        //     {footer ? <Modal.Footer>{footer}</Modal.Footer> : null}
        // </StyledModal>
    );
};


const TinyModalWrapper = styled(Modal)<{ expand: boolean }>`
   
    ${(props) =>
    props.expand
        ? `
        .modal-header, .modal-body, .modal-header {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .modal-dialog {
            width: 100%;
            max-width: none;
            margin: 0;
            height: 100%;
        }
        .modal-content {
            height: 100%;
            border-radius: 0;
        }
        .modal-body {
            overflow-y: auto;
            padding: 2rem;
        }

        .modal-header {
            padding: 20px 2rem;
            box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
            h5 {
                padding-top: 2px;
            }
        }
        
        .footerContent {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            flex-direction: column;
            gap: 16px;
        }
        @media screen and (min-width: 768px){
            .modal-body {
                overflow-y: auto;
                .modal-body-container {
                    padding: 26px calc(40px + 20px) 8px 20px;
                    &--end {
                        padding-bottom: 24px
                    }
                    p {
                        margin-bottom: 0;
                    }
                }
                
                .modal__title {
                    transition: opacity 0.2s ease-in-out;
                    margin-bottom: 12px;
                }
            }
        
       
        
            .modal-body {
                padding: 0;
            }
        
            .close {
                float: left;
                opacity: 1;
            }
        
            .modal-footer {
                padding: 20px !important;
                box-shadow: none !important;
                @media screen and (min-width: 768px) {
                    .footerContent {
                        flex-direction: row;
                    }
                }
            }
        
            .modal-dialog .modal-content {
                border-radius: 12px;
                max-width: 560px;
                height: auto;
                margin: 20px;
            }
        }
        `
        : `
        .modal-body {
            overflow-y: auto;
            .modal-body-container {
                padding: 26px calc(40px + 20px) 8px 20px;
                &--end {
                    padding-bottom: 24px
                }
                p {
                    margin-bottom: 0;
                }
            }
            
            .modal__title {
                transition: opacity 0.2s ease-in-out;
                margin-bottom: 12px;
            }
        }
       
    
        .modal-body {
            padding: 0;
        }
    
        .close {
            float: left;
            opacity: 1;
        }
    
        .modal-footer {
            padding: 20px !important;
            box-shadow: none !important;
    
            .footerContent {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 auto;
                flex-direction: column;
                gap: 16px;
    
                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
            }
        }
    
        .modal-dialog .modal-content {
            border-radius: 12px;
            max-width: 560px;
            height: auto;
            margin: 20px;
        }
    
       `}

`;

export default TinyModal;

