import { useContext } from 'react';
import { OrderContext } from './order-context';

const useOrderContext = () => {
    const context = useContext(OrderContext);

    if (context === undefined) {
        throw new Error('useOrderContext must be within a OrderProvider');
    }

    return context;
};

export default useOrderContext;
